import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listPendingRamassage, createCourseRam } from '../../../slices/course.slice';
import { listLivreurByAdm } from '../../../slices/user.slice';
import { api_file_url_product } from '../../../config/Params.constant';

import Badge from 'react-bootstrap/Badge';


const CourseRamassageCreate = () => {


    const { loading, error } = useSelector(state => state.course_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_order, set_lst_order] = useState([])
    const [lst_liv, set_lst_liv] = useState([])
    const [sel_liv, set_sel_liv] = useState()
    const [target_liv, set_target_liv] = useState()
    const [nbr_cmd, set_nbr_cmd] = useState(0)


    const [sel_item, set_sel_item] = useState()
    const [show_details, set_show_details] = useState(false)



    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listPendingRamassage()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                let tmp = res?.rows.map(el => ({ ...el, checked: true }))
                set_lst_order(tmp)
            }
            dispatch(listLivreurByAdm()).unwrap().then(res => {
                if (Array.isArray(res?.rows)) {
                    set_lst_liv(res?.rows)
                }

            })

        })
    }

    const onViewDetails = value => {
        set_sel_item(value)
        set_show_details(true)
    }

    const onItemCheck = (id) => {
        let tmp = lst_order.map(el => el?.id === id ? ({ ...el, checked: !el?.checked }) : el)
        set_lst_order(tmp)
    }

    const updateTotalValues = () => {
        if (Array.isArray(lst_order))
            set_nbr_cmd(lst_order.filter(el => el?.checked).length)
        else
            set_nbr_cmd(0)
    }

    const onSubmitCourse = () => {
        if (sel_liv && target_liv && Array.isArray(lst_order) && nbr_cmd > 0) {
            let course_ram_bags = lst_order.filter(el => el?.checked === true).map(el => ({ chefOrderId: el?.id }))
            let course = {
                id_liv: sel_liv,
                course_ram_bags: course_ram_bags
            }

            dispatch(createCourseRam(course)).unwrap().then(res => {
                if (res?.done) {
                    history('/app/ramassage')
                }
            })
        }
    }

    useEffect(() => {
        load_data()
    }, [])

    useEffect(() => {
        updateTotalValues()
    }, [lst_order])

    useEffect(() => {
        if (sel_liv && Array.isArray(lst_liv))
            set_target_liv(lst_liv.find(el => el?.id == sel_liv))
    }, [sel_liv])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2 mb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'truck']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Nouvelle Course de ramassage <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_order) ? lst_order.length : '--'}</span></h5>
                            <button className='btn btn-sm btn-success px-3 py-2 float-end' onClick={onSubmitCourse} disabled={(nbr_cmd <= 0 || !sel_liv || loading)} >Créer une course <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>

                        </div>

                        <div className="col-md-12 px-0">
                            <div className="row m-0">
                                <div className="col-md-6 offset-md-3  py-2">
                                    <div className="input-group input-group-sm">
                                        <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Choisissez un Livreur</label>
                                        <select placeholder="..." onChange={e => set_sel_liv(e?.target?.value)} className={`form-select form-select-sm bg-input-gray form-control `} >
                                            <option>___</option>
                                            {
                                                Array.isArray(lst_liv) && lst_liv.map(el => <option value={el?.id}>{el?.nom} {el?.prenom}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {target_liv &&
                                <div className="row m-0 py-2 dvdr-t-gray">
                                    <div className="col-md-1 text-center pt-1">
                                        <b className="icon-item-round">
                                            <FontAwesomeIcon icon={['fas', 'user-ninja']} />
                                        </b>
                                    </div>

                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Livreur</b>
                                        <p className='text-muted mb-1 fnt-sm'>{target_liv?.nom || '---'} {target_liv?.prenom || '---'}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Tel</b>
                                        <p className='text-muted mb-1 fnt-sm'>{target_liv?.tel || '---'}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Adresse</b>
                                        <p className='text-muted mb-1 fnt-sm'>{target_liv?.adresse || '---'}</p>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <OrderChefList lst_order={lst_order} onItemCheck={onItemCheck} onViewDetails={onViewDetails} />
            </div>



        </div>
    );
};


const OrderChefList = ({ lst_order, onItemCheck, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <Form.Check checked={el?.checked} onChange={e => onItemCheck(el?.id)} />
                            </div>
                            <div className="col-md-3">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Etats</b>
                                <Badge bg="success">{el?.status} </Badge>
                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Nbr Repas</b>
                                <Badge bg="danger">{el?.total_quantity}</Badge>
                            </div>

                            <div className="col-md-4 text-end pt-1">
                                <button className='btn btn-sm btn-dark px-3' onClick={e => onViewDetails(el)}>Voir Plus <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );

}
export default CourseRamassageCreate;