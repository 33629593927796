import React, { useEffect } from 'react';
import login_bg from '../../../assets/img/slider-bg.png';
import logo from '../../../assets/img/logo.svg';
import { useNavigate, Link } from "react-router-dom";
import LoginForm from '../../../ui/form/login.form';
import { clear, login as doLogin } from '../../../slices/auth.slice';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);
    const onSubmit = data => {
        dispatch(doLogin(data))
    }

    useEffect(() => {
        if (loggedUser) {
            navigate('/app')
        }
    }, [loggedUser])


    return (
        <div className="row m-0 login-container bg-wt">
            <div className="col-md-6 h-100 ">
                <div className="col-md-12 py-2">
                    <Link to={'/'}>
                        <button className='btn btn-dark btn-sm px-3'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} /> Accueil</button>
                    </Link>
                </div>
                <div className="col-md-12 text-center mt-5 mb-5">
                    <img src={logo} alt="" style={{ width: 180, transform: 'scale(2)' }} />
                </div>
                <LoginForm loading={loading} error={error} onLogin={onSubmit} />

                <div className="col-md-12 px-5">
                    <p className='px-3'>Pas encore membre ? <Link to={'/home/register'} className='text-dark'><b>S'inscrire</b></Link></p>
                </div>
            </div>

            <div className="col-md-6 h-100 position-relative text-center" style={{ background: '#ffc53e', minHeight: 720, overflowX: 'hidden' }} >
                <img src={login_bg} className="slider-img" alt="" width='50%' />
            </div>

        </div >
    );
};

export default Login;