import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class FactureService {

    listByChef() {
        let token = storage.getToken();
        return axios.get(`${api_url}/chef/facture/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listDebts() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/facture/debts`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listDebtsByChef(id_chef) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/facture/debts/chef/${id_chef}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    createFacture(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/facture/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    deleteFacture(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/facture/cancel/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listByAdm() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/facture/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


}

export default new FactureService()