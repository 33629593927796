import React, { useEffect, useState } from 'react';
import liv_bg from '../../../assets/img/liv_bg.png'
import ic_ram from '../../../assets/img/ic_ram.png'
import ic_liv from '../../../assets/img/ic_liv.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDispatch } from "react-redux";
import { listCourseRamByLiv, listCourseLivByLiv } from '../../../slices/course.slice';
import Badge from 'react-bootstrap/Badge'

const Home = () => {

    const dispatch = useDispatch();
    const [lst_ram, set_lst_ram] = useState([])
    const [lst_liv, set_lst_liv] = useState([])

    const [nbr_ram, set_nbr_ram] = useState(0)
    const [nbr_liv, set_nbr_liv] = useState(0)

    const load_data = () => {
        dispatch(listCourseRamByLiv()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_ram(res?.rows)
            }

            dispatch(listCourseLivByLiv()).unwrap().then(res => {
                if (Array.isArray(res?.rows)) {
                    set_lst_liv(res?.rows)
                }
            })
        })
    }

    const calculNbrRam = () => {
        if (Array.isArray(lst_ram)) {
            let sum = lst_ram.reduce(
                (accumulator, currentValue) => accumulator + (Array.isArray(currentValue?.course_ram_bags) ? currentValue?.course_ram_bags?.length : 0),
                0
            );
            set_nbr_ram(sum)
        }
    }

    const calculNbrLiv = () => {
        if (Array.isArray(lst_liv)) {
            let sum = lst_liv.reduce(
                (accumulator, currentValue) => accumulator + (Array.isArray(currentValue?.course_liv_bags) ? currentValue?.course_liv_bags?.length : 0),
                0
            );
            set_nbr_liv(sum)
        }
    }

    useEffect(() => {
        calculNbrRam()
    }, [lst_ram])

    useEffect(() => {
        calculNbrLiv()
    }, [lst_liv])

    useEffect(() => {
        load_data()
    }, [])


    return (
        <div className="col-md-12 p-2">
            <div className='row m-0 home-banner'>

                <div className="col-md-8 pt-5">
                    <h3 className='m-0'>
                        <span className='d-block'>Bonjour ,</span>
                        <span className='d-block fnt-med'>Bienvenu dans votre espace Livreur</span>
                    </h3>
                </div>

                <div className="col-md-4 position-relative banner-container">
                    <img src={liv_bg} alt="" width='100%' className='banner-home-img' />
                </div>

            </div>


            <div className='row m-0'>
                <div className="col-md-6 p-2">
                    <div className="row m-0 home-banner p-2 text-center">
                        <h5 className='text-center'><Badge bg='warning' className='text-dark'>Ramassage</Badge></h5>
                        <div className="col-md-12">
                            <img src={ic_ram} width="72" alt="" />
                        </div>
                        <h3 className='fnt-w8 m-0'>{nbr_ram}</h3>
                        <b className='text-muted fnt-sm fnt-w5'>Commandes</b>
                    </div>
                </div>

                <div className="col-md-6 p-2">
                    <div className="row m-0 home-banner p-2 text-center">
                        <h5 className='text-center'><Badge bg='warning' className='text-dark'>Livraison</Badge></h5>
                        <div className="col-md-12">
                            <img src={ic_liv} width="72" alt="" />
                        </div>
                        <h3 className='fnt-w8 m-0'>{nbr_liv}</h3>
                        <b className='text-muted fnt-sm fnt-w5'>Commandes</b>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;