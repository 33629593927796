import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from "react-redux";
import { chef_routes } from '../../../utils/userRoutes.helper';
import AsideMenu from '../../../ui/menu/AsideMenu';
import { clear, logout, loadLoggedUser } from '../../../slices/auth.slice';
import Home from './Home';
import Product from '../product/Product';
import Order from '../order/Order';
import FactureChef from '../facture/FactureChef';


const MainContainerChef = ({ match }) => {

    const [isOpen, setIsopen] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);

    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(3);

    const dologout = () => {
        dispatch(logout())
        navigate("/login");
    }

    useEffect(() => {
        dispatch(loadLoggedUser())
    }, [])


    return (
        <>
            <AsideMenu loggedUser={loggedUser} user_routes={chef_routes} onLogOut={dologout} isOpen={isOpen} setIsopen={setIsopen} />

            <div className={isOpen ? "rw-cont bg-gray" : "rw-cont rw-cont-mini bg-gray"}>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path='/product' element={<Product />} />
                    <Route path='/order' element={<Order />} />
                    <Route path='/bills' element={<FactureChef />} />
                </Routes>
            </div>

        </>
    );
};

export default MainContainerChef;