import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listDebts } from '../../../slices/facture.slice';
import { api_file_url_product } from '../../../config/Params.constant';
import { user_roles } from '../../../utils/Enums.helper';


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';


const Dettes = () => {


    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_item, set_sel_item] = useState()
    const [show_details, set_show_details] = useState(false)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listDebts()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    const onViewDetails = value => {
        history(`${value}`)
    }

    const onCloseDetails = () => {
        set_sel_item(null)
        set_show_details(false)
    }


    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row no-marg">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'hand-holding-usd']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Dettes Chefs <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>


                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Nom  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>


            <div className="row m-0 mt-2">
                <DettesList lst_order={lst_data} onViewDetails={onViewDetails} />
            </div>



        </div>
    );
};


const DettesList = ({ lst_order, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'user-ninja']} />
                                </b>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.id}</p>
                            </div>

                            <div className="col-md-3">
                                <b className='txt-dark fnt-w7  fnt-sm'>Chef</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.nom || '---'} {el?.prenom || '---'}</p>
                            </div>
                            <div className="col-md-4 text-center">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Commande non-payé</b>
                                <Badge bg="danger">{Array.isArray(el?.userOrderChef) && el?.userOrderChef.length} </Badge>
                            </div>


                            <div className="col-md-2 text-end pt-1">
                                <button className='btn btn-sm btn-success px-3' onClick={e => onViewDetails(el?.id)}>Facturer <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );

}
export default Dettes;