import { useEffect, useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { clear, checkAuth } from '../slices/auth.slice'
import { useDispatch, useSelector } from "react-redux";
import MainContainerAdmin from '../features/admin/main/main.container';
import MainContainerLivreur from '../features/livreur/main/main.container';
import MainContainerChef from '../features/chef/main/main.container';
import MainContainerClient from '../features/client/main/main.container';

const PrivateRoute = () => {

    const dispatch = useDispatch()
    const { loading, error } = useSelector(state => state.auth_store);
    const [checked, set_checked] = useState(false)
    const [user, set_user] = useState(false)

    useEffect(() => {
        dispatch(checkAuth()).unwrap().then(res => {
            console.log(res)
            set_user(res)
            set_checked(true)
        }).catch(err => {
            set_user(null)
            set_checked(true)
        })

    }, [])

    const renderDashboard = () => {
        if (user?.role == 'client')
            return <MainContainerClient />
        else if (user?.role == 'chef')
            return <MainContainerChef />
        else if (user?.role == 'livreur')
            return <MainContainerLivreur />
        else if (user?.role == 'admin')
            return <MainContainerAdmin />
        else
            return <Navigate to="/login" />
    }

    return checked ? user ? renderDashboard() : <Navigate to="/login" /> : <></>
};

export default PrivateRoute;