import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { motion } from "framer-motion"
import { Wizard, useWizard } from 'react-use-wizard';

import ic_plat_1 from '../../../assets/img/plat_1.jpg';
import { api_file_url_product } from '../../../config/Params.constant';
import Alert from 'react-bootstrap/Alert';


const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 800 : -800,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 800 : -800,
            opacity: 0,

        };
    },
};

const StepRepaSel = ({ sel_repas, set_sel_repas, sel_abn, previousStep }) => {

    const { activeStep, goToStep, handleStep, nextStep } = useWizard();
    const [total_quantity, set_total_quantity] = useState(0)
    const [step_error, set_step_error] = useState('')

    useEffect(() => {
        return () => {
            previousStep.current = activeStep;
        };
    }, [activeStep, previousStep]);

    const onChangeQuantity = (item, quantity) => {
        if (Array.isArray(sel_repas)) {
            let tmp = [...sel_repas]
            tmp = tmp.map(el => el?.id == item?.id ? { ...el, quantity: ((el.quantity + quantity) > 0 ? (el.quantity + quantity) : 0) } : el)

            let total = tmp.reduce(
                (accumulator, currentValue) => accumulator + currentValue?.quantity,
                0
            );

            if (total <= sel_abn?.nbr_repas) {
                set_sel_repas(tmp)
                set_total_quantity(total)
            }
        }
    }

    handleStep(async () => {
        return doValidate();

    });

    const doValidate = () => {
        set_step_error('')
        return new Promise((resolve, reject) => {
            let tmp = []
            if (Array.isArray(sel_repas))
                tmp = sel_repas.filter(el => el?.quantity > 0)
            if (tmp.length > 0)
                resolve()
            else {
                set_step_error('Vous devez choisir les repas.')
                reject()
            }
        })
    }




    return (

        <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
                duration: .1
            }}
            custom={activeStep - previousStep.current}>

            <div className="row m-0 py-0" >

                <div className="col-md-12 text-center my-4">
                    <h2 className='fnt-wb '>
                        <figcaption className="blockquote-footer " style={{ color: "#000" }}>
                            JE CHOISIS MES REPAS
                        </figcaption>
                    </h2>
                    {step_error &&
                        <Alert variant='warning'>
                            <FontAwesomeIcon className='me-3' icon={['fas', 'warning']} /> {step_error}
                        </Alert>
                    }
                </div>

            </div>

            <div className="row m-0 px-5">


                {Array.isArray(sel_repas) && sel_repas.map(el =>
                    <div className="col-md-3" key={`crd-prd-${el?.id}`}>
                        <div className="col-md-12 prod-card p-0 my-1">

                            <div className="row m-0 p-0">



                                <div className="col-md-12 p-0">
                                    <img src={el?.img ? `${api_file_url_product}${el?.img}` : ``} alt="" width="100%" style={{ maxHeight: 321 }} />
                                </div>

                                <div className="col-md-12 py-1 text-center">
                                    <b className='fnt-wb d-block' style={{ minHeight: 60 }}>{el?.nom}</b>
                                    <p className='fnt-sm m-0' style={{ minHeight: 60 }}>{el?.description}</p>
                                </div>
                                <hr className='my-1' />

                                {/* <div className="col-md-12 py-0">
                                    <b><FontAwesomeIcon className='' icon={['fas', 'pepper-hot']} /></b>
                                    <b><FontAwesomeIcon className='text-muted' icon={['fas', 'pepper-hot']} /></b>
                                    <b><FontAwesomeIcon className='text-muted' icon={['fas', 'pepper-hot']} /></b>
                                    <b><FontAwesomeIcon className='text-muted' icon={['fas', 'pepper-hot']} /></b>
                                    <b><FontAwesomeIcon className='text-muted' icon={['fas', 'pepper-hot']} /></b>
                                    <b className='float-end fnt-sm'>SPICY</b>
                                </div> */}

                                <div className="col-md-12 py-2 text-center">
                                    <span className="badge bg-dark fnt-w4">{el?.cuisine}</span>
                                </div>

                                <hr />

                                <div className="col-md-4 offset-md-4 ps-0 pt-2 pb-3">
                                    <button onClick={e => onChangeQuantity(el, -1)} className="btn d-inline-block w-25 btn-dark no-border-rad p-0 h-100 csm-btn border-rad-start"><FontAwesomeIcon icon={['fas', 'minus']} /></button>
                                    <input type="number" id="typeNumber" className="form-control csm-form-control no-border-rad text-center brd-col-black fnt-w5 d-inline-block w-50 h-100" value={el?.quantity} />
                                    <button onClick={e => onChangeQuantity(el, 1)} className="btn d-inline-block w-25 btn-dark no-border-rad p-0 h-100 csm-btn border-rad-end"><FontAwesomeIcon icon={['fas', 'plus']} /></button>
                                </div>



                            </div>
                        </div>
                    </div>
                )}


            </div>

            <div className="col-md-12 text-center position-fixed bottom-0 start-0 bg-yellow py-2 b-shadow">
                <h4 className='m-0'>{`${total_quantity}/${sel_abn?.nbr_repas}`} Repas</h4>
            </div>
        </motion.div>
    );
};

export default StepRepaSel;