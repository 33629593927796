import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from "react-redux";
import { livreur_routes } from '../../../utils/userRoutes.helper';
import AsideMenu from '../../../ui/menu/AsideMenu';
import { clear, logout, loadLoggedUser } from '../../../slices/auth.slice';
import Home from './Home';
import CoursesRamassageLivreur from '../course/CoursesRamassage';
import CoursesLivLivreur from '../course/CoursesLiv';


const MainContainerLivreur = ({ match }) => {

    const [isOpen, setIsopen] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);

    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(3);

    const dologout = () => {
        dispatch(logout())
        navigate("/login");
    }

    useEffect(() => {
        dispatch(loadLoggedUser())
    }, [])


    return (
        <>
            <AsideMenu loggedUser={loggedUser} user_routes={livreur_routes} onLogOut={dologout} isOpen={isOpen} setIsopen={setIsopen} />

            <div className={isOpen ? "rw-cont bg-gray" : "rw-cont rw-cont-mini bg-gray"}>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path='ram' element={<CoursesRamassageLivreur />} />
                    <Route path='liv' element={<CoursesLivLivreur />} />
                </Routes>
            </div>

        </>
    );
};

export default MainContainerLivreur;