import { token_name, user_data_name } from '../config/Params.constant';
var Storage = (function () {

  var getData = function () {
    var user = JSON.parse(localStorage.getItem(user_data_name));
    return user
  };
  var getToken = function () {
    var tkn = localStorage.getItem(token_name);
    return tkn
  };

  var setData = function (user) {
    localStorage.setItem(user_data_name, JSON.stringify(user));
  };
  var setToken = function (tkn) {
    localStorage.setItem(token_name, tkn);
  };

  var logout = function () {
    localStorage.removeItem(user_data_name);
    localStorage.removeItem(token_name);
  };


  return {
    getData: getData,
    setData: setData,
    logout: logout,
    setToken: setToken,
    getToken: getToken,
  }

})();

export default Storage;