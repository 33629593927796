import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

const SubscriptionForm = ({ loading, error, onCreate, onUpdate, show, onClose, sel_item, ...props }) => {

    const roles = ['admin', 'chef', 'livreur']
    const validationSchema = Yup.object().shape({
        nbr_repas: Yup.string().required('Ce champ est obligatoire !'),
        unit_price: Yup.string().required('Ce champ est obligatoire !'),
        price: Yup.string().required('Ce champ est obligatoire !'),
        actif: Yup.boolean().required('Ce champ est obligatoire !'),
        priceId: Yup.string().required('Ce champ est obligatoire !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (sel_item) {
            let tmp = { ...data, id: sel_item?.id }
            onUpdate(tmp)
        } else {
            onCreate(data)
        }
        reset()
    }

    useEffect(() => {
        if (sel_item) {
            setValue('nbr_repas', sel_item?.nbr_repas)
            setValue('unit_price', sel_item?.unit_price)
            setValue('price', sel_item?.price)
            setValue('actif', sel_item?.actif)
            setValue('priceId', sel_item?.priceId)
        } else {
            reset()
        }
    }, [sel_item])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        {(sel_item) ?
                            <FontAwesomeIcon icon={['fas', 'cube']} />
                            :
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                        }
                    </b>
                    {(sel_item) ? `Fiche Offre ${sel_item?.ref}` : 'Nouvelle Offre'}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                        <div className="col-md-8 form-group col-6">
                            <label className="fnt-w7 fnt-sm">Nombre de Repas</label>
                            <input type="number" {...register("nbr_repas")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.nbr_repas?.message}</p>
                        </div>

                        <div className="col-md-6 form-group col-6">
                            <label className="fnt-w7 fnt-sm">Prix Unitaire (€)</label>
                            <input type="number" step="any" {...register("unit_price")} className={`form-control form-control-sm`} />
                            <p className="text-danger">{errors.unit_price?.message}</p>
                        </div>


                        <div className="col-md-6 form-group col-6">
                            <label className="fnt-w7 fnt-sm">Prix Offre (€)</label>
                            <input type="number" step="any" {...register("price")} className={`form-control form-control-sm`} />
                            <p className="text-danger">{errors.price?.message}</p>
                        </div>
                        <div className="col-md-6 form-group col-6">
                            <label className="fnt-w7 fnt-sm">Stripe PriceId</label>
                            <input type="text" step="any" {...register("priceId")} className={`form-control form-control-sm`} />
                            <p className="text-danger">{errors.priceId?.message}</p>
                        </div>

                        <div className="form-group col-md-12 mb-2">
                            <label className="fnt-w7 fnt-sm">Publié</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("actif")}
                            />
                        </div>







                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default SubscriptionForm;