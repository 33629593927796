import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class SubscriptionService {

    listPublicSubscription() {
        return axios.get(`${api_url}/public/subscription/list`);
    }

    list(data) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/subscription/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    create(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/subscription/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    update(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/subscription/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    delete(data) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/subscription/delete/${data?.ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

}

export default new SubscriptionService()