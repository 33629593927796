import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useDispatch, useSelector } from "react-redux";
import { updateByAdm, uploadImage } from '../../../slices/product.slice';

import Form from 'react-bootstrap/Form'
import Offcanvas from 'react-bootstrap/Offcanvas';

import default_img from '../../../assets/img/img-empty.png'
import { api_file_url_product } from '../../../config/Params.constant';


const ProductForm = ({ is_edit, item, load_data, show, onClose, ...props }) => {

    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const loading = false;
    const [is_img_picked, set_is_img_picked] = useState(false)


    const validationSchema = Yup.object().shape({
        nom: Yup.string(),
        cuisine: Yup.string(),
        category: Yup.string(),
        sub_category: Yup.string(),
        description: Yup.string(),
        composition: Yup.string(),
        max_order: Yup.string(),
        actif: Yup.boolean(),

    });


    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        if (is_img_picked) {
            const formData = new FormData();
            formData.append('file', sel_img);

            dispatch(uploadImage(formData)).unwrap().then(res_upload => {
                if (res_upload?.path)
                    dispatch(updateByAdm({
                        ...item,
                        nom: data?.nom,
                        cuisine: data?.cuisine,
                        category: data?.category,
                        sub_category: data?.sub_category,
                        description: data?.description,
                        composition: data?.composition,
                        max_order: data?.max_order,
                        actif: data?.actif,
                        img: res_upload?.path
                    })).unwrap().then(res => {
                        if (res?.done) {
                            load_data()
                            reset()
                            set_sel_img(null)
                            set_is_img_picked(false)
                            set_sel_img_tmp(null)
                        }
                    })
            })
        } else {
            dispatch(updateByAdm({
                ...item,
                nom: data?.nom,
                cuisine: data?.cuisine,
                category: data?.category,
                sub_category: data?.sub_category,
                description: data?.description,
                composition: data?.composition,
                actif: data?.actif,
                max_order: data?.max_order
            })).unwrap().then(res => {
                if (res?.done) {
                    load_data()
                    reset()
                    set_sel_img(null)
                    set_is_img_picked(false)
                    set_sel_img_tmp(null)
                }
            })
        }
    }

    const inpt_file_ref = useRef(null);
    const [sel_img, set_sel_img] = useState(null);
    const [sel_img_tmp, set_sel_img_tmp] = useState(null);

    const onPickClick = () => {
        inpt_file_ref.current.click();
    }

    const onImgChange = (e) => {
        set_sel_img(e.target.files[0]);
        set_sel_img_tmp(URL.createObjectURL(e.target.files[0]));
        set_is_img_picked(true)
    }

    useEffect(() => {
        if (is_edit && item) {
            console.log(item)
            //set_sel_img_tmp(item?.img)
            //set_sel_img(item?.img)

            setValue('nom', item?.nom)
            setValue('cuisine', item?.cuisine)
            setValue('category', item?.category)
            setValue('sub_category', item?.sub_category)
            setValue('description', item?.description)
            setValue('composition', item?.composition)
            setValue('max_order', item?.max_order)
            setValue('actif', item?.actif)

        }
    }, [is_edit, item])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'utensils']} />
                    </b>
                    Fiche Repas
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <hr width="100%" />
                <div className='col-md-12'>

                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0  mt-2 p-2">

                        <div className="col-md-12 text-center">

                            <div className='p-1 img-profile-cont bg-gray ' style={{ cursor: 'pointer', width: 180 }} onClick={onPickClick}>
                                {
                                    (sel_img && sel_img_tmp) ?
                                        <img src={sel_img_tmp} alt="" className='img-profile' style={{ opacity: 1 }} />
                                        :
                                        <img src={`${api_file_url_product}${item?.img}`} alt="" className='img-profile' style={{ opacity: .6 }} />
                                }
                            </div>
                            <div class="form-group d-none">
                                <Form.Control type="file" onChange={onImgChange} ref={inpt_file_ref} />
                            </div>
                        </div>
                        <div className="col-md-12  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Publié</label>
                                <Form.Check
                                    className='ms-3'
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    {...register("actif")}
                                />
                            </div>
                        </div>
                        <div className="col-md-12  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Nom du Repas</label>
                                <input type="text"  {...register("nom")} className={`form-control form-control-sm bg-input-gray ${errors.nom ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.nom?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-6  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Categorie</label>
                                <select placeholder="..."  {...register("category")} className={`form-select form-select-sm bg-input-gray ${errors.category ? 'is-invalid' : 'form-control'}`} >
                                    <option value="tous">Tous</option>
                                </select>
                                <p className="text-danger">{errors.category?.message}</p>
                            </div>
                        </div>
                        <div className="col-md-6  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Sous-Categorie</label>
                                <select placeholder="..."  {...register("sub_category")} className={`form-select form-select-sm bg-input-gray ${errors.sub_category ? 'is-invalid' : 'form-control'}`} >
                                    <option value="tous">Tous</option>
                                </select>
                                <p className="text-danger">{errors.sub_category?.message}</p>
                            </div>
                        </div>



                        <div className="col-md-8  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Type de Cuisine</label>
                                <input type="text" {...register("cuisine")} className={`form-control form-control-sm bg-input-gray ${errors.cuisine ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.cuisine?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-12  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Description</label>
                                <input type="text"  {...register("description")} className={`form-control form-control-sm bg-input-gray ${errors.description ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.description?.message}</p>
                            </div>
                        </div>
                        <div className="col-md-12  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Composition</label>
                                <input type="text"  {...register("composition")} className={`form-control form-control-sm bg-input-gray ${errors.composition ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.composition?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-12  py-1 pt-2">
                            <div className="input-group input-group-sm">
                                <label className='input-group-text fnt-w5 label-blue bg-dark text-light'>Nombre Max de Repas</label>
                                <input type="text"  {...register("max_order")} className={`form-control form-control-sm bg-input-gray ${errors.max_order ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.max_order?.message}</p>
                            </div>
                        </div>

                        <hr width="100%" className='my-2 mt-4' />
                        <div className="col-md-12 text-end  bg-white py-1" >
                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :

                                <>
                                    <button type="button" className="btn btn-secondary btn-login me-2" onClick={e => onClose()}><FontAwesomeIcon icon={['fas', 'times-circle']} /> Annuler</button>
                                    <button type="submit" className="btn btn-success btn-login " ><FontAwesomeIcon icon={['fas', 'check-circle']} /> Confirmer</button>
                                </>
                            }
                        </div>

                    </form>


                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default ProductForm;