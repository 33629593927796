import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { motion } from "framer-motion"
import { Wizard, useWizard } from 'react-use-wizard';
import Alert from 'react-bootstrap/Alert';


// const lst_abn = [
//     {
//         ref: 'abn_8',
//         nbr_repas: 8,
//         prix_par_repas: 8.00,
//         prix: 64.00
//     },
//     {
//         ref: 'abn_10',
//         nbr_repas: 10,
//         prix_par_repas: 7.50,
//         prix: 75.00
//     },
//     {
//         ref: 'abn_12',
//         nbr_repas: 12,
//         prix_par_repas: 7.00,
//         prix: 84.00
//     },
//     {
//         ref: 'abn_14',
//         nbr_repas: 14,
//         prix_par_repas: 6.50,
//         prix: 91.00
//     },
//     {
//         ref: 'abn_16',
//         nbr_repas: 16,
//         prix_par_repas: 6.00,
//         prix: 96.00
//     },
// ]

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 800 : -800,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 800 : -800,
            opacity: 0,

        };
    },
};

const StepAbnSel = ({ sel_abn, set_sel_abn, lst_abn, previousStep }) => {

    const { activeStep, goToStep, handleStep, nextStep } = useWizard();
    //const box = document.querySelector('#product-slider1');
    const box = useRef()
    const [step_error, set_step_error] = useState('')

    useEffect(() => {
        return () => {
            previousStep.current = activeStep;
        };
    }, [activeStep, previousStep]);

    const btnpressprev = () => {

        let width = box.current?.clientWidth;
        box.current.scrollLeft = box.current?.scrollLeft - width / 3;
        console.log(width)
        autoCheckUpdate('prev')
    }

    const btnpressnext = () => {
        let width = box.current?.clientWidth;
        box.current.scrollLeft = box.current?.scrollLeft + width / 3;
        console.log(width)
        autoCheckUpdate('next')
    }

    const autoCheckUpdate = (event) => {

        let indx = lst_abn.findIndex(el => el.ref == sel_abn?.ref)

        if (event == 'next') {
            if (lst_abn.length > indx + 2)
                set_sel_abn(lst_abn[indx + 1].ref)
        } else {
            if (indx - 1 > 0 && lst_abn.length > (indx - 1))
                set_sel_abn(lst_abn[indx - 1].ref)
        }

    }

    useEffect(() => {
        console.log(sel_abn)
    }, [sel_abn])


    handleStep(async () => {
        return doValidate();

    });

    const doValidate = () => {
        set_step_error('')
        return new Promise((resolve, reject) => {
            if (sel_abn)
                resolve()
            else {
                set_step_error('Vous devez choisir une formule.')
                reject()
            }
        })
    }

    return (

        <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
                duration: .1
            }}
            custom={activeStep - previousStep.current}>

            <div className="row m-0 py-5">

                <div className="col-md-12 text-center my-3">
                    <h2 className='fnt-wb '>
                        <figcaption className="blockquote-footer " style={{ color: "#000" }}>
                            JE CHOISIS MA FORMULE
                        </figcaption>

                    </h2>
                    {step_error &&
                        <Alert variant='warning'>
                            <FontAwesomeIcon className='me-3' icon={['fas', 'warning']} /> {step_error}
                        </Alert>
                    }
                </div>


                <div className="col-md-3  d-flex align-items-center text-end">
                    <button className='btn btn-dark ms-auto' onClick={btnpressprev}> <FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} /></button>
                </div>

                <div className=" col-md-6  d-flex flex-nowrap py-5 px-2" id='product-slider' ref={box} style={{ overflowX: 'hidden', scrollBehavior: 'smooth' }}>


                    {Array.isArray(lst_abn) && lst_abn.map(el =>
                        <div className="col-md-4 text-center px-2">
                            <div onClick={e => set_sel_abn(el)} className={`col-md-12 abn-card p-0 py-5 position-relative ${el?.ref == sel_abn?.ref && 'abn-card-sel'}`}>
                                <Form.Check
                                    type='checkbox'
                                    label=""
                                    className='m-0 p-0 abn-checkbox'
                                    checked={el?.ref == sel_abn?.ref}
                                    onChange={(e) => set_sel_abn(el)}
                                />

                                <div className="row m-0 p-0">

                                    <div className="col-md-12 p-0">
                                        <h1 className='m-0 txt-yellow fnt-wb'>{el?.nbr_repas}</h1>
                                        <h6 className='m-0 fnt-wb'>REPAS PAR SEMAINE</h6>
                                        <h6 className='m-0 mt-5 fnt-wb'>{el?.unit_price} € / REPAS</h6>
                                        <h4 className='m-0 mt-3 text-success mt-2 fnt-wb'>{el?.price} €</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}




                </div>

                <div className="col-md-3 d-flex align-items-center">
                    <button className='btn btn-dark' onClick={btnpressnext}> <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>

                </div>


            </div>
        </motion.div>
    );
};

export default StepAbnSel;