import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listCourseLivByLiv } from '../../../slices/course.slice';
import { courseLiv_status } from '../../../utils/Enums.helper';

import Badge from 'react-bootstrap/Badge';


const CoursesLivLivreur = () => {


    const { loading, error } = useSelector(state => state.course_store);
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_item, set_sel_item] = useState(null)
    const [show_details, set_show_details] = useState(false)

    const load_data = () => {
        dispatch(listCourseLivByLiv()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    const onViewDetails = data => {
        set_sel_item(data)
        set_show_details(true)
    }

    const onCloseDetails = () => {
        set_sel_item(null)
        set_show_details(false)
    }

    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'truck']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Livraison <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>

                        </div>


                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <CoursesLivList lst_order={lst_data} onViewDetails={onViewDetails} />
            </div>

            <CoursesDetails sel_item={sel_item} show={show_details} onClose={onCloseDetails} />


        </div>
    );
};


const CoursesLivList = ({ lst_order, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1' key={`crs-${el?.id}`}>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'motorcycle']} />
                                </b>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Etat</b>
                                {el?.status == courseLiv_status.EN_COURS ? <Badge bg='warning' className='text-dark'>{el?.status}</Badge> : <Badge bg='success'>{el?.status}</Badge>}
                            </div>

                            <div className="col-md-6">
                                <b className='txt-dark fnt-w7  fnt-sm'>Nbr Commandes</b>
                                <p className='text-muted mb-1 fnt-sm'>{Array.isArray(el?.course_liv_bags) ? el?.course_liv_bags?.length : '---'}  </p>
                            </div>



                            <div className="col-md-1 text-end pt-1">

                                <button className='btn btn-sm btn-success w-100' onClick={e => onViewDetails(el)}><FontAwesomeIcon className='me-0' icon={['fas', 'long-arrow-alt-right']} /></button>

                            </div>

                        </div>
                    </div>
                )
            }


        </div>
    );

}

const CoursesDetails = ({ sel_item, show, onClose, ...props }) => {

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-75">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark '>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'motorcycle']} />
                    </b>
                    Fiche Course <Badge bg='success'>#{sel_item?.ref}</Badge>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row m-0">
                    {
                        Array.isArray(sel_item?.course_liv_bags) && sel_item?.course_liv_bags.map(el =>
                            <div className='col-md-12 p-0 home-banner my-1'>

                                <div className="row m-0 pt-2 dvdr-b-gray">

                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                        <p className='text-muted mb-1 fnt-sm'>#{el?.order?.ref}</p>
                                    </div>
                                    <div className="col-md-2">
                                        <b className='txt-dark fnt-w7  fnt-sm d-block'>Etats</b>
                                        <Badge bg="warning" className='text-dark'>{el?.status} </Badge>

                                    </div>

                                    <div className="col-md-2">
                                        <b className='txt-dark fnt-w7  fnt-sm d-block'>Client</b>
                                        <Badge bg="secondary">{el?.order?.nom || '---'} {el?.order?.prenom || '---'}</Badge>
                                    </div>

                                    <div className="col-md-2">
                                        <b className='txt-dark fnt-w7  fnt-sm d-block'>tel</b>
                                        <Badge bg="secondary">{el?.order?.tel || '---'}</Badge>
                                    </div>

                                    <div className="col-md-2">
                                        <b className='txt-dark fnt-w7  fnt-sm d-block'>Adresse</b>
                                        <Badge bg="secondary">{el?.order?.adresse || '---'}</Badge>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </div>
            </Offcanvas.Body>
        </Offcanvas>

    )
}
export default CoursesLivLivreur;