import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';



const FactureAdmDetails = ({ item, show, onClose, onCancelFacture, loading, ...props }) => {


    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'eye']} />
                    </b>
                    Facture #{item?.ref}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <hr width="100%" />
                <div className="col-md-12 py-2 text-end">
                    <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onCancelFacture(item?.ref)}><FontAwesomeIcon icon={['fas', 'trash']} /> Annuler la Facture</button>
                </div>
                <div className='col-md-12'>
                    <table className="table table-striped fnt-sm">
                        <thead>
                            <tr>
                                <th scope="col">Commande</th>
                                <th scope="col">Nbr Plats</th>
                                <th scope="col">Prix/Plats</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isNaN(item?.prix_unitaire) && Array.isArray(item?.facturebags) && item?.facturebags.map(el =>
                                <tr>
                                    <th scope="row">#{el?.chef_order?.ref}</th>
                                    <td>{el?.quantity}</td>
                                    <td>{item?.prix_unitaire} €</td>
                                    <td>{!isNaN(+el?.quantity) && (+el?.quantity * item?.prix_unitaire)} €</td>
                                </tr>
                            )}

                            <tr className='fnt-w8'>
                                <th colspan="3" >Total</th>
                                <td>{item?.prix_total} €</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default FactureAdmDetails;