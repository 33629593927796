import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainHome from "./features/client/main/main.container"
import Login from "./features/public/login/Login"
import PrivateRoute from './app/PrivateRoute';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<MainHome />} />
        <Route path="/home/*" element={<MainHome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app/*" element={<PrivateRoute />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
