import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'

const AsideMenu = ({ nbr_notif_unread, user_routes, loggedUser, onLogOut, isOpen, setIsopen }) => {

    return (
        <>
            <nav className={isOpen ? "hd-navbar fixed-top" : "hd-navbar fixed-top hd-navbar-mini"}>
                <button className="sidebar-toggle" onClick={() => setIsopen(!isOpen)}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </button>

                <div className="navbar-custom-menu pr-4" style={{ float: "right", marginRight: "24px", display: "inline-block" }}>
                    <ul className="nav navbar-nav" style={{ flexDirection: "row" }}>

                        <li className="nav-item ">
                            <Dropdown drop="down" className='user-hd-info'>
                                <Dropdown.Toggle variant="default  fnt-largr" className="no-arrow   dvdr-l-gray no-brd-rad ps-3 pe-3" id="dropdown-basic" >
                                    <b className="fnt-w5 ">
                                        <span>
                                            <FontAwesomeIcon icon={['fas', 'user-circle']} />
                                        </span>
                                        <span className="fnt-med ps-2">{`${loggedUser?.email}(${loggedUser?.role})`}</span>
                                    </b>

                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>



                        <li className="nav-item ps-3">

                            <Dropdown drop="down"  >
                                <Dropdown.Toggle variant="default  fnt-largr  no-arrow btn_round_notif p-0" id="dropdown-basic" >
                                    <FontAwesomeIcon icon={['fas', 'bell']} />
                                    {nbr_notif_unread > 0 &&
                                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fnt-sm mt-2">
                                            {nbr_notif_unread}
                                        </span>
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">
                                </Dropdown.Menu>
                            </Dropdown>


                        </li>


                    </ul>
                </div>


            </nav>

            <aside className={isOpen ? "main-sidebar" : "main-sidebar mini-sidebar"}>
                <section className="sidebar">

                    <div className="col-md-12 py-4" style={{ background: '#ffc53e' }}>
                        <a href={`/app`} className={isOpen ? "sidebar_logo" : "sidebar_logo sidebar_logo_mini"}>
                            <img src={logo} alt="" style={{ transform: 'scale(2)' }} />
                        </a>
                    </div>



                    <ul className="sidebar-menu mt-4 pt-4">
                        {
                            Array.isArray(user_routes) && user_routes.map(el =>
                                <li>
                                    <NavLink to={el?.path} activeClassName="activel" exact={el?.exact} >
                                        <span className="icon">
                                            <FontAwesomeIcon icon={['fas', `${el?.icon}`]} />
                                        </span>
                                        <span className={isOpen ? "title" : "hidebal"}>
                                            {el?.label}
                                        </span>

                                    </NavLink>
                                </li>
                            )
                        }


                    </ul>

                    <ul>
                        <li className="cont_log_out">
                            <button className="btn_log_out" onClick={onLogOut}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                </span></button>
                        </li>
                    </ul>

                </section>
            </aside>

        </>
    );
};

export default AsideMenu;