import React, { useState, useEffect } from 'react';
import bg_client from '../../../assets/img/client_bg.svg'

import { api_file_url_product, api_file_url_avatar } from '../../../config/Params.constant';
import { listPublicProduct } from '../../../slices/product.slice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChefDetails from './ChefDetails';


const Repas = () => {

    const [lst_repas, set_lst_repas] = useState([])
    const [sel_chef, set_sel_chef] = useState()
    const [show_chef, set_show_chef] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onViewChef = data => {
        set_sel_chef(data)
        set_show_chef(true)
    }
    const load_data = () => {
        dispatch(listPublicProduct()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_repas(res?.rows)
            }
        })
    }

    useEffect(() => { load_data() }, [])


    return (
        <>
            <div className="row m-0 py-2 px-5 brd-btm-gray mb-3">
                <div className="col-md-8 text-start ps-0">
                    <h3 className='m-0 pt-5'>
                        <span className='d-block'>Nos Repas</span>
                        <span className='d-block fnt-med'> Découvrez le menu de la semaine !</span>
                    </h3>
                </div>
                <div className="col-md-4 text-end">
                    <img src={bg_client} width='30%' alt="" />
                </div>
            </div>

            <div className="row m-0 py-2 px-5 mb-5">

                {Array.isArray(lst_repas) && lst_repas.map(el =>
                    <div className="col-md-3" key={`crd-prd-${el?.id}`}>
                        <div className="col-md-12 prod-card p-0 my-1">

                            <div className="row m-0 p-0">



                                <div className="col-md-12 p-0">
                                    <img src={el?.img ? `${api_file_url_product}${el?.img}` : ``} alt="" width="100%" style={{ maxHeight: 321 }} />
                                </div>

                                <div className="col-md-12 py-1 text-center">
                                    <b className='fnt-wb d-block' style={{ minHeight: 60 }}>{el?.nom}</b>
                                    <p className='fnt-sm m-0' style={{ minHeight: 60 }}>{el?.description}</p>
                                </div>
                                <hr className='my-1' />

                                <div className="col-md-12 py-2">
                                    <span className='d-block fnt-sm fnt-w5'>Cuisine <span className="badge bg-dark fnt-w4 float-end">{el?.cuisine}</span></span>

                                </div>
                                <div className="col-md-12 py-2">
                                    <span className='d-block fnt-sm fnt-w5'>Categorie <span className="badge bg-dark fnt-w4 float-end">{el?.category}/{el?.sub_category}</span></span>

                                </div>



                                <hr />

                                <div className="col-md-12 py-2">
                                    <span className='d-block fnt-sm fnt-w5'><span className=''>Chef</span>  <button onClick={e => onViewChef(el?.productChef)} className='btn btn-sm btn-dark float-end fnt-sm'>{el?.productChef?.nom} {el?.productChef?.prenom} <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button> </span>

                                </div>

                                {/* <div className="col-md-4 offset-md-4 ps-0 pt-2 pb-3">
                                    <button onClick={e => onChangeQuantity(el, -1)} className="btn d-inline-block w-25 btn-dark no-border-rad p-0 h-100 csm-btn border-rad-start"><FontAwesomeIcon icon={['fas', 'minus']} /></button>
                                    <input type="number" id="typeNumber" className="form-control csm-form-control no-border-rad text-center brd-col-black fnt-w5 d-inline-block w-50 h-100" value={el?.quantity} />
                                    <button onClick={e => onChangeQuantity(el, 1)} className="btn d-inline-block w-25 btn-dark no-border-rad p-0 h-100 csm-btn border-rad-end"><FontAwesomeIcon icon={['fas', 'plus']} /></button>
                                </div> */}



                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ChefDetails chef={sel_chef} show={show_chef} onClose={e => set_show_chef(false)} />
        </>
    );
};

export default Repas;