import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { completeByClient } from '../../../slices/order.slice';

import Badge from 'react-bootstrap/Badge'

const PaymentComplete = () => {

    const [order, setOrder] = useState(null)

    const { loading, error } = useSelector(state => state.order_store);
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    const [pay_msg, set_pay_msg] = useState()

    const load_data = () => {
        set_pay_msg('')
        let payId = params?.get("payment_intent")
        let redirect_status = params?.get("redirect_status")
        console.log(payId)
        console.log(redirect_status)


        if (redirect_status != 'succeeded') {
            set_pay_msg(<Badge bg='danger' className='px-5 py-2'><FontAwesomeIcon className='me-2' icon={['fas', 'exclamation-circle']} /> Paiement échoué !</Badge>)
            setInterval(() => { navigate('/app/espace') }, 3000)
        }
        else if (payId) {
            dispatch(completeByClient(payId)).unwrap().then(res => {
                if (res?.done) {
                    set_pay_msg(<Badge bg='success' className='px-5 py-2'><FontAwesomeIcon className='me-2' icon={['fas', 'check-circle']} /> Paiement réussi</Badge>)
                    setInterval(() => { navigate('/app/espace') }, 3000)
                }
            }).catch(err => {
                setInterval(() => { navigate('/app/espace') }, 3000)
            })
        }
    }


    useEffect(() => {
        load_data()
    }, [params])


    return (
        <div className='row m-0 my-5' >

            <div className="col-md-6 offset-md-3 text-center p-3 card shadow my-5" >
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
                <h1>Résultat du paiement</h1>
                <div className="col-md-12 p-4 text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <p>{pay_msg}</p>
            </div>

        </div>
    );
};

export default PaymentComplete;