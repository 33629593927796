import React, { useState, useEffect } from 'react';
import bg_client from '../../../assets/img/client_bg.svg'

import { loadLoggedUser } from '../../../slices/auth.slice';
import { listByClient } from '../../../slices/order.slice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderList from './OrderList';
import ClientProfileForm from '../../../ui/form/clientProfile.form';

const ProfileClient = () => {

    const { loggedUser } = useSelector(state => state.auth_store);
    const { loading, error } = useSelector(state => state.order_store);
    const [lst_order, set_lst_order] = useState([])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const load_data = () => {
        dispatch(loadLoggedUser())
        dispatch(listByClient()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_order(res?.rows)
            }
        })

    }

    useEffect(() => {
        load_data()
    }, [])


    return (
        <>
            <div className="row m-0 py-2 px-5 brd-btm-gray mb-3">
                <div className="col-md-8 text-start ps-0">
                    <h3 className='m-0 pt-5'>
                        <span className='d-block'>Bonjour {loggedUser?.prenom} ,</span>
                        <span className='d-block fnt-med'> Bienvenu dans votre espace client</span>
                    </h3>
                </div>
                <div className="col-md-4 text-end">
                    <img src={bg_client} width='30%' alt="" />
                </div>
            </div>

            <div className="row m-0 py-2 px-5 mb-5">
                <Tabs
                    defaultActiveKey="order"
                    id="tabs-space-client"
                    className="mb-3"
                >
                    <Tab eventKey="order" title={<><FontAwesomeIcon className='me-3' icon={['fas', 'shopping-basket']} /> Mes Commandes</>}>
                        <OrderList lst_order={lst_order} loading={loading} error={error} />
                    </Tab>

                    <Tab eventKey="profile" title={<><FontAwesomeIcon className='me-3' icon={['fas', 'user-tie']} /> Mon Profil</>}>
                        <ClientProfileForm userProfile={loggedUser} />
                    </Tab>
                </Tabs>
            </div>

        </>
    );
};

export default ProfileClient;