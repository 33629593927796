import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listDebtsByChef, createFacture } from '../../../slices/facture.slice';
import { api_file_url_product } from '../../../config/Params.constant';

import Badge from 'react-bootstrap/Badge';


const FactureCreate = () => {


    const { loading, error } = useSelector(state => state.facture_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [target_chef, set_target_chef] = useState()
    const [sel_item, set_sel_item] = useState()
    const [show_details, set_show_details] = useState(false)

    const [unit_price, set_unit_price] = useState(7.00)
    const [nbr_cmd, set_nbr_cmd] = useState(0)
    const [nbr_repas, set_nbr_repas] = useState(0)
    const [total_price, set_total_price] = useState(0.00)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listDebtsByChef(params?.ref)).unwrap().then(res => {
            if (Array.isArray(res?.orders?.rows)) {
                let tmp = res?.orders?.rows.map(el => ({ ...el, checked: true }))
                set_lst_data(tmp)
            }
            set_target_chef(res?.chef)
        })
    }

    const onViewDetails = value => {
        set_sel_item(value)
        set_show_details(true)
    }

    const onItemCheck = (id) => {
        let tmp = lst_data.map(el => el?.id === id ? ({ ...el, checked: !el?.checked }) : el)
        set_lst_data(tmp)
    }

    const updateTotalValues = () => {
        if (Array.isArray(lst_data)) {
            let tot_cmd = 0
            let tot_repas = 0
            let tot_price = 0.00
            lst_data.forEach(el => {
                if (el?.checked) {
                    tot_cmd += 1
                    tot_repas += +el?.total_quantity
                    tot_price += (+el?.total_quantity * +unit_price)
                }
            });
            set_nbr_cmd(tot_cmd)
            set_nbr_repas(tot_repas)
            set_total_price(tot_price)
        }
    }

    const onSubmitFacture = () => {
        if (unit_price > 0 && nbr_repas > 0 && !isNaN(unit_price) && Array.isArray(lst_data) && target_chef?.id) {
            let facturebags = lst_data.filter(el => el?.checked === true).map(el => ({ chefOrderId: el?.id, quantity: el?.total_quantity }))
            let bill = {
                prix_unitaire: unit_price,
                id_chef: target_chef?.id,
                facturebags: facturebags
            }

            dispatch(createFacture(bill)).unwrap().then(res => {
                if (res?.done) {
                    history('/app/bills')
                }
            })
        }
    }

    useEffect(() => {
        load_data()
    }, [])

    useEffect(() => {
        updateTotalValues()
    }, [lst_data, unit_price])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2 mb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'hand-holding-usd']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Facturation <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>
                            <button className='btn btn-sm btn-success px-3 py-2 float-end' onClick={onSubmitFacture} disabled={(unit_price <= 0 || nbr_repas <= 0 || !target_chef || loading)} >Créer Facture <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>

                        </div>

                        <div className="col-md-12 dvdr-b-gray">
                            <div className="row m-0 py-2">
                                <div className="col-md-1 text-center pt-1">
                                    <b className="icon-item-round">
                                        <FontAwesomeIcon icon={['fas', 'user-ninja']} />
                                    </b>
                                </div>

                                <div className="col-md-3">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Chef</b>
                                    <p className='text-muted mb-1 fnt-sm'>{target_chef?.nom || '---'} {target_chef?.prenom || '---'}</p>
                                </div>
                                <div className="col-md-3">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Tel</b>
                                    <p className='text-muted mb-1 fnt-sm'>{target_chef?.tel || '---'}</p>
                                </div>
                                <div className="col-md-3">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Adresse</b>
                                    <p className='text-muted mb-1 fnt-sm'>{target_chef?.adresse || '---'}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 text-center">
                            <b className='text-muted fnt-w4 fnt-sm'>Nombre de commande Total</b>
                            <p className='fnt-w7 mb-1 fnt-med'>{nbr_cmd}</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <b className='text-muted fnt-w4 fnt-sm d-block'>Prix unitaire</b>
                            <input className='text-center' type="number" onChange={e => set_unit_price(e.target.value)} value={unit_price} /> €
                        </div>

                        <div className="col-md-3 text-center">
                            <b className='text-muted fnt-w4 fnt-sm'>Nombre de repas Total</b>
                            <p className='fnt-w7 mb-1 fnt-med'>{nbr_repas}</p>
                        </div>

                        <div className="col-md-3 text-center">
                            <b className='text-muted fnt-w4 fnt-sm'>Prix Total</b>
                            <p className='fnt-w7 mb-1 fnt-med'>{total_price} €</p>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <DettesList lst_order={lst_data} unit_price={unit_price} onItemCheck={onItemCheck} onViewDetails={onViewDetails} />
            </div>



        </div>
    );
};


const DettesList = ({ lst_order, unit_price, onItemCheck, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <Form.Check checked={el?.checked} onChange={e => onItemCheck(el?.id)} />
                            </div>
                            <div className="col-md-3">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Etats</b>
                                <Badge bg="success">{el?.status} </Badge>
                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Nbr Repas</b>
                                <Badge bg="danger">{el?.total_quantity}</Badge>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Total</b>
                                <Badge bg="primary px-3">{(+el?.total_quantity * +unit_price)} €</Badge>
                            </div>



                            <div className="col-md-2 text-end pt-1">
                                <button className='btn btn-sm btn-dark px-3' onClick={e => onViewDetails(el)}>Voir Plus <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );

}
export default FactureCreate;