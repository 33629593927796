import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listByAdm, createAdm, createChef, createLivreur, updateUser } from '../../../slices/user.slice';
import { api_file_url_product } from '../../../config/Params.constant';
import { user_roles } from '../../../utils/Enums.helper';


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';
import UserForm from './UserCreateForm';
import UserDetails from './UserDetails';
import UserPasswordForm from './UserPasswordForm';


const Users = () => {


    const { loading, error } = useSelector(state => state.user_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [lst_chef, set_lst_chef] = useState([])
    const [lst_liv, set_lst_liv] = useState([])
    const [lst_client, set_lst_client] = useState([])
    const [lst_adm, set_lst_adm] = useState([])


    const [sel_item, set_sel_item] = useState()
    const [is_edit, set_is_edit] = useState()

    const [show_create, set_show_create] = useState(false)
    const [show_details, set_show_details] = useState(false)
    const [show_edit_pass, set_show_edit_pass] = useState(false)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listByAdm()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
                if (sel_item)
                    set_sel_item(res?.rows.find(el => el?.id == sel_item?.id))
            }
        })
    }

    const onAddClicked = () => {
        set_is_edit(false)
        set_sel_item(null)
        set_show_create(true)
    }

    const onCloseAdd = () => {
        set_show_create(false)
        set_is_edit(false)
    }

    const OnAddUser = (data) => {
        if (data?.role == 'admin')
            dispatch(createAdm(data)).unwrap().then(res => {
                if (res?.done) {
                    onCloseAdd()
                    load_data()
                }
            })
        else if (data?.role == 'livreur')
            dispatch(createLivreur(data)).unwrap().then(res => {
                if (res?.done) {
                    onCloseAdd()
                    load_data()
                }
            })
        else if (data?.role == 'chef')
            dispatch(createChef(data)).unwrap().then(res => {
                if (res?.done) {
                    onCloseAdd()
                    load_data()
                }
            })
    }

    const onUpdateUser = (data) => {
        dispatch(updateUser(data)).unwrap().then(res => {
            if (res?.done) {
                onCloseAdd()
                load_data()
            }
        })
    }

    const onViewDetails = value => {
        set_sel_item(value)
        set_show_details(true)
    }

    const onCloseDetails = () => {
        set_sel_item(null)
        set_show_details(false)
    }

    const onEditUserClicked = () => {
        if (sel_item) {
            set_is_edit(true)
            set_show_create(true)
        }
    }

    const onEditPassClicked = () => {
        if (sel_item)
            set_show_edit_pass(true)
    }

    const onUpdatePass = (data) => {
        dispatch(updateUser(data)).unwrap().then(res => {
            if (res?.done) {
                onCloseEditPass()
                load_data()
            }
        })
    }

    const onCloseEditPass = () => {
        set_show_edit_pass(false)
    }


    useEffect(() => {
        load_data()
    }, [])

    useEffect(() => {
        if (Array.isArray(lst_data)) {
            set_lst_chef(lst_data.filter(el => el?.role === user_roles.chef))
            set_lst_liv(lst_data.filter(el => el?.role === user_roles.livreur))
            set_lst_client(lst_data.filter(el => el?.role === user_roles.client))
            set_lst_adm(lst_data.filter(el => el?.role === user_roles.admin))
        }
    }, [lst_data])

    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row no-marg">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'users']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Utilisateurs <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>
                            <button className='btn btn-success float-end' onClick={onAddClicked} ><FontAwesomeIcon icon={['fas', 'plus']} />  Nouveau Compte</button>


                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>


            <div className="row m-0 mt-2">
                <Tabs
                    defaultActiveKey="chef"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="chef" title={<><FontAwesomeIcon icon={['fas', 'user-ninja']} /> Chefs <Badge bg="danger">{Array.isArray(lst_chef) && lst_chef.length}</Badge></>}>
                        <UserList lst_users={lst_chef} onViewDetails={onViewDetails} />
                    </Tab>
                    <Tab eventKey="livreur" title={<><FontAwesomeIcon icon={['fas', 'user-astronaut']} /> Livreurs <Badge bg="danger">{Array.isArray(lst_liv) && lst_liv.length}</Badge></>}>
                        <UserList lst_users={lst_liv} onViewDetails={onViewDetails} />
                    </Tab>
                    <Tab eventKey="client" title={<><FontAwesomeIcon icon={['fas', 'user-tie']} /> Clients <Badge bg="danger">{Array.isArray(lst_client) && lst_client.length}</Badge></>} >
                        <UserList lst_users={lst_client} onViewDetails={onViewDetails} />
                    </Tab>
                    <Tab eventKey="admin" title={<><FontAwesomeIcon icon={['fas', 'user-shield']} /> Admins <Badge bg="danger">{Array.isArray(lst_adm) && lst_adm.length}</Badge></>} >
                        <UserList lst_users={lst_adm} onViewDetails={onViewDetails} />
                    </Tab>
                </Tabs>
            </div>

            <UserForm show={show_create} sel_item={sel_item} is_edit={is_edit} onRegister={OnAddUser} onUpdate={onUpdateUser} onClose={onCloseAdd} loading={loading} error={error} />
            <UserDetails show={show_details} sel_item={sel_item} onEditPass={onEditPassClicked} onEditUser={onEditUserClicked} onClose={onCloseDetails} loading={loading} error={error} />
            <UserPasswordForm show={show_edit_pass} sel_item={sel_item} onUpdate={onUpdatePass} onClose={onCloseEditPass} loading={loading} error={error} />



        </div>
    );
};


const UserList = ({ lst_users, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_users) && lst_users.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'user']} />
                                </b>
                            </div>
                            <div className="col-md-1">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.id}</p>
                            </div>

                            <div className="col-md-3">
                                <b className='txt-dark fnt-w7  fnt-sm'>Nom Complet</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.nom || '---'} {el?.prenom || '---'}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>E-mail</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.email} </p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Tel</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.tel || '---'} </p>
                            </div>
                            <div className="col-md-1">
                                <b className='d-block txt-dark fnt-w7  fnt-sm'>Etat</b>
                                {el?.isActif ? <Badge bg='success'>actif</Badge> : <Badge bg='danger'>inactif</Badge>}
                            </div>

                            <div className="col-md-2 text-end pt-1">
                                <button className='btn btn-sm btn-success px-3' onClick={e => onViewDetails(el)}><FontAwesomeIcon className='me-3' icon={['fas', 'eye']} /> Voir Plus</button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );

}
export default Users;