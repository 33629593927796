import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from 'react-bootstrap/Badge'
import chef_bg from '../../../assets/img/chef_bg.png'

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewByAdm } from '../../../slices/user.slice';
import { api_file_url_product } from '../../../config/Params.constant';




const Home = () => {

    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.user_store);

    const [overview, set_overview] = useState(null);

    const load_data = () => {
        dispatch(getOverviewByAdm()).unwrap().then(res => {
            set_overview(res)
        })
    }

    useEffect(() => {
        load_data()
    }, [])


    return (
        <div className='row m-0 py-3'>

            <div className="col-md-12 pb-3">
                <div className='row m-0 home-banner'>

                    <div className="col-md-8 pt-5">
                        <h3 className='m-0'>
                            <span className='d-block'>Bonjour ,</span>
                            <span className='d-block fnt-med'>Bienvenu dans votre espace Admin</span>
                        </h3>
                    </div>

                    <div className="col-md-4 position-relative banner-container">
                        <img src={chef_bg} alt="" width='60%' className='banner-home-img' />
                    </div>

                </div>
            </div>

            <div className="col-md-8">
                <div className='row m-0 text-center home-banner p-2'>
                    <div className="col-md-12">
                        <h5 className='fnt-w7 dvdr-b-gray pb-2'>
                            <span className='float-start'><FontAwesomeIcon icon={['fas', 'box']} /></span>
                            Commandes
                            <Link to={`order`}>
                                <button className='btn btn-sm btn-dark float-end py-0 px-4'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                            </Link>
                        </h5>
                    </div>

                    <div className="col-md-4">
                        <h3>{overview?.nbr_cmd_cours || 0}</h3>
                        <Badge bg='dark'>En-Cours</Badge>
                    </div>
                    <div className="col-md-4">
                        <h3>{overview?.nbr_cmd_att_ram || 0}</h3>
                        <Badge bg='warning' className='text-dark'>En-Attente-Ramassage</Badge>
                    </div>
                    <div className="col-md-4">
                        <h3>{overview?.nbr_cmd_att_liv || 0}</h3>
                        <Badge bg='success'>Prêt-A-Livrer</Badge>
                    </div>

                </div>
            </div>

            <div className="col-md-4">
                <div className='row m-0 text-center home-banner p-2'>
                    <div className="col-md-12">
                        <h5 className='fnt-w7 dvdr-b-gray pb-2'>
                            <span className='float-start'><FontAwesomeIcon icon={['fas', 'money-bill']} /></span>
                            Facturation
                            <Link to={`debts`}>
                                <button className='btn btn-sm btn-dark float-end py-0 px-4'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                            </Link>
                        </h5>
                    </div>

                    <div className="col-md-6">
                        <h3>{overview?.balance || '0.00'} €</h3>
                        <Badge bg='success'>Balance-Stripe</Badge>
                    </div>
                    <div className="col-md-6">
                        <h3>{overview?.nbr_cmd_bebts || 0}</h3>
                        <Badge bg='danger'>Non-Payé(commande)</Badge>
                    </div>

                </div>
            </div>

            <div className="col-md-6 mt-3">
                <div className='row m-0 text-center home-banner p-2'>
                    <div className="col-md-12">
                        <h5 className='fnt-w7 dvdr-b-gray pb-2'>
                            <span className='float-start'><FontAwesomeIcon icon={['fas', 'truck-loading']} /></span>
                            Ramassage
                            <Link to={`ramassage`}>
                                <button className='btn btn-sm btn-dark float-end py-0 px-4'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                            </Link>
                        </h5>
                    </div>

                    <div className="col-md-6">
                        <h3>{overview?.nbr_cmd_att_ram || 0}</h3>
                        <Badge bg='warning' className='text-dark'>En-Attente(Commande)</Badge>
                    </div>
                    <div className="col-md-6">
                        <h3>{overview?.nbr_ram_cours || 0}</h3>
                        <Badge bg='success'>En-Cours(course)</Badge>
                    </div>

                </div>

            </div>

            <div className="col-md-6 mt-3">
                <div className='row m-0 text-center home-banner p-2'>
                    <div className="col-md-12">
                        <h5 className='fnt-w7 dvdr-b-gray pb-2'>
                            <span className='float-start'><FontAwesomeIcon icon={['fas', 'truck']} /></span>
                            Livraison
                            <Link to={`livraison`}>
                                <button className='btn btn-sm btn-dark float-end py-0 px-4'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                            </Link>
                        </h5>
                    </div>

                    <div className="col-md-6">
                        <h3>{overview?.nbr_cmd_att_liv || 0}</h3>
                        <Badge bg='warning' className='text-dark'>En-Attente(commande)</Badge>
                    </div>
                    <div className="col-md-6">
                        <h3>{overview?.nbr_liv_cours || 0}</h3>
                        <Badge bg='success'>En-Cours(course)</Badge>
                    </div>

                </div>
            </div>




        </div>
    );
};

export default Home;