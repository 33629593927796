import React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ sel_abn, sel_repas }) => {
    const {
        isLoading,
        activeStep,
        stepCount,
        isLastStep,
        nextStep,
        previousStep,

    } = useWizard();


    const render_next_text = () => {
        if (activeStep == 0)
            return 'JE CHOISIS MES REPAS'
        else if (activeStep == 1)
            return 'JE CONFIRME MA SELECTION'
        else if (activeStep == 2)
            return 'JE COMMANDE'
    }

    const render_prev_text = () => {
        if (activeStep == 0)
            return ''
        else if (activeStep == 1)
            return 'JE CHOISIS MA FORMULE'
        else if (activeStep == 2)
            return 'JE CHOISIS MES REPAS'
    }

    return (
        <>
            <div className="row m-0 py-4 brd-btm-gray">
                <div className="col-md-3 text-end">
                    {activeStep > 0 &&
                        <button className='btn btn-dark w-100 px-3 py-3 fnt-w5' onClick={() => previousStep()}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} className='me-3' /> {render_prev_text()}</button>
                    }
                </div>
                <div className="col-md-6 text-center">

                    <div className="row m-0">
                        <div className="col-md-12">
                            <nav className="k-stepper">
                                <ol className="k-step-list">
                                    <li className="k-step">
                                        <a href="" className="k-step-link" tabindex="0" aria-current="step">
                                            <span className="k-step-indic">
                                                <b className="k-icon"><FontAwesomeIcon icon={['fas', 'cash-register']} /></b>
                                            </span>
                                            <span className="k-label">
                                                <b className="fnt-w5">Formules</b>
                                            </span>
                                        </a>

                                    </li>
                                    <li className="k-step">
                                        <a href="" className="k-step-link" tabindex="-1" >
                                            <span className={`k-step-indic ${activeStep < 1 && 'not-done'} `}>
                                                <b className="k-icon"><FontAwesomeIcon icon={['fas', 'utensils']} /></b>
                                            </span>
                                            <span className="k-label">
                                                <b className="fnt-w5">Repas</b>
                                            </span>
                                        </a>

                                    </li>
                                    <li className="k-step">
                                        <a href="" className="k-step-link" tabindex="-1" >
                                            <span className={`k-step-indic ${activeStep < 2 && 'not-done'} `}>
                                                <b className="k-icon"><FontAwesomeIcon icon={['fas', 'check']} /></b>
                                            </span>
                                            <span className="k-label">
                                                <b className="fnt-w5">Confirmation</b>
                                            </span>
                                        </a>

                                    </li>
                                </ol>

                                <div className="k-stepper-line"></div>
                            </nav>
                        </div>


                    </div>

                    <div className="row m-0">
                        <h4> <span className=""></span></h4>
                        <b className='d-block pb-2'><span className='badge fnt-xsm bg-dark px-2 py-1 brd-rad-32'>{`${activeStep + 1} / ${stepCount}`}</span></b>
                    </div>
                </div>
                <div className="col-md-3 ">
                    {(!isLastStep) &&
                        <button className='btn btn-dark w-100 px-3 py-3 fnt-w5 ' onClick={() => nextStep()}> <span className='me-3'>{render_next_text()}</span> <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                    }
                </div>
            </div>

        </>
    );
}

export default Header;