import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth.slice';
import productReducer from '../slices/product.slice';
import subscriptionReducer from '../slices/subscription.slice';
import orderReducer from '../slices/order.slice';
import factureReducer from '../slices/facture.slice';
import userReducer from '../slices/user.slice';
import courseReducer from '../slices/course.slice';

export const store = configureStore({
  reducer: {
    auth_store: authReducer,
    product_store: productReducer,
    subscription_store: subscriptionReducer,
    order_store: orderReducer,
    facture_store: factureReducer,
    user_store: userReducer,
    course_store: courseReducer,
  },
});
