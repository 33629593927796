import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewCourseRam, validateCourseRam } from '../../../slices/course.slice';
import { courseRam_status, courseRamBag_status } from '../../../utils/Enums.helper';

import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';


const CourseRamassageValidate = () => {


    const { loading, error } = useSelector(state => state.course_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [course, set_course] = useState()
    const [lst_course_bag, set_lst_course_bag] = useState([])
    const [nbr_ramasse, set_nbr_ramasse] = useState(0)

    const [sel_item, set_sel_item] = useState()
    const [show_details, set_show_details] = useState(false)


    const load_data = () => {
        if (params?.ref)
            dispatch(viewCourseRam(params?.ref)).unwrap().then(res => {
                if (res?.entity) {
                    set_course(res?.entity)
                }
                if (Array.isArray(res?.entity?.course_ram_bags)) {
                    set_lst_course_bag(res?.entity?.course_ram_bags.map(el => ({ ...el, checked: false })))
                }

            })
    }

    const onViewDetails = value => {
        set_sel_item(value)
        set_show_details(true)
    }

    const onItemCheck = (id) => {
        let tmp = lst_course_bag.map(el => el?.id === id ? ({ ...el, checked: !el?.checked }) : el)
        set_lst_course_bag(tmp)
    }

    const updateTotalValues = () => {
        if (Array.isArray(lst_course_bag)) {
            if (course?.status == courseRam_status.EN_COURS)
                set_nbr_ramasse(lst_course_bag.filter(el => el?.checked).length)
            else
                set_nbr_ramasse(lst_course_bag.filter(el => el?.status == courseRamBag_status.RAMASSE).length)
        } else
            set_nbr_ramasse(0)
    }

    const onValidateCourse = () => {
        if (course && Array.isArray(lst_course_bag)) {
            let cr = {
                ref: course?.ref,
                course_ram_bags: lst_course_bag
            }

            dispatch(validateCourseRam(cr)).unwrap().then(res => {
                if (res?.done) {
                    history('/app/ramassage')
                }
            })
        }
    }

    useEffect(() => {
        load_data()
    }, [])

    useEffect(() => {
        updateTotalValues()
    }, [lst_course_bag])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2 mb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'truck']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Valider Course de ramassage <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_course_bag) ? lst_course_bag.length : '--'}</span></h5>
                            <button className='btn btn-sm btn-success px-3 py-2 float-end' onClick={onValidateCourse} disabled={(course?.status == courseRam_status.VALIDE || loading)} >Valider le ramassage <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>

                        </div>

                        <div className="col-md-12 px-0">

                            {course?.ramasseur &&
                                <div className="row m-0 py-2 dvdr-b-gray">
                                    <div className="col-md-1 text-center pt-1">
                                        <b className="icon-item-round">
                                            <FontAwesomeIcon icon={['fas', 'user-ninja']} />
                                        </b>
                                    </div>

                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Livreur</b>
                                        <p className='text-muted mb-1 fnt-sm'>{course?.ramasseur?.nom || '---'} {course?.ramasseur?.prenom || '---'}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Tel</b>
                                        <p className='text-muted mb-1 fnt-sm'>{course?.ramasseur?.tel || '---'}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <b className='txt-dark fnt-w7  fnt-sm'>Adresse</b>
                                        <p className='text-muted mb-1 fnt-sm'>{course?.ramasseur?.adresse || '---'}</p>
                                    </div>
                                </div>
                            }
                            <div className="col-md-12 pt-2 text-center">
                                <Badge bg='success'>{`${nbr_ramasse}/${Array.isArray(lst_course_bag) ? lst_course_bag.length : '--'}`} Ramassées</Badge>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <CourseBagList lst_order={lst_course_bag} onItemCheck={onItemCheck} onViewDetails={onViewDetails} courseStatus={course?.status} />
            </div>



        </div>
    );
};


const CourseBagList = ({ lst_order, onItemCheck, onViewDetails, courseStatus }) => {

    return (
        <div className="row m-0">

            {
                courseStatus == courseRam_status.EN_COURS &&
                <Alert variant='dark' className='py-1'>
                    <b><FontAwesomeIcon className='me-4' icon={['fas', 'check-to-slot']} /></b>  Cocher les commandes ramassées
                </Alert>
            }

            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                {
                                    courseStatus == courseRam_status.EN_COURS ?
                                        <Form.Check checked={el?.checked} onChange={e => onItemCheck(el?.id)} />
                                        :
                                        <Form.Check checked={el?.status == courseRamBag_status.RAMASSE} />
                                }
                            </div>
                            <div className="col-md-3">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.chef_order?.ref}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Etats</b>
                                {
                                    courseStatus == courseRam_status.EN_COURS ?
                                        <Badge bg="warning" className='text-dark'>{el?.status} </Badge>
                                        :
                                        <Badge bg={`${el?.status == courseRamBag_status.RAMASSE ? 'success' : 'danger'}`}>{el?.status} </Badge>
                                }

                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Nbr Repas</b>
                                <Badge bg="danger">{el?.chef_order?.total_quantity}</Badge>
                            </div>

                            <div className="col-md-4 text-end pt-1">
                                <button className='btn btn-sm btn-dark px-3' onClick={e => onViewDetails(el?.chef_order)}>Voir Plus <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );

}
export default CourseRamassageValidate;