import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class OrderService {

    placeByClient(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/client/order/place`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listByClient() {
        let token = storage.getToken();
        return axios.get(`${api_url}/client/order/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    viewByClient(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/client/order/view/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    completeByClient(payid) {
        let token = storage.getToken();
        return axios.get(`${api_url}/client/order/complete/${payid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listByChef() {
        let token = storage.getToken();
        return axios.get(`${api_url}/chef/order/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    orderChefSetReady(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/chef/order/set-ready/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listByAdm() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/order/list/on-going`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    viewByAdm(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/order/view/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    dispatchByAdm(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/order/confirm/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    updateByAdm(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/order/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


}

export default new OrderService()