import React, { useState, useEffect } from 'react';
import chef_bg from '../../../assets/img/chef_bg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ic_plat_1 from '../../../assets/img/plat_1.jpg';
import ic_plat_2 from '../../../assets/img/plat_2.jpeg';
import ic_plat_3 from '../../../assets/img/plat_3.jpg';
import ic_plat_4 from '../../../assets/img/plat_4.jpg';

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewByChef } from '../../../slices/user.slice';
import { api_file_url_product } from '../../../config/Params.constant';


const Home = () => {

    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.user_store);

    const [overview, set_overview] = useState(null);

    const load_data = () => {
        dispatch(getOverviewByChef()).unwrap().then(res => {
            set_overview(res)
        })
    }

    useEffect(() => {
        load_data()
    }, [])


    return (
        <div className="col-md-12 p-2">
            <div className='row m-0 home-banner'>

                <div className="col-md-8 pt-5">
                    <h3 className='m-0'>
                        <span className='d-block'>Bonjour ,</span>
                        <span className='d-block fnt-med'>Bienvenu dans votre espace chef</span>
                    </h3>
                </div>

                <div className="col-md-4 position-relative banner-container">
                    <img src={chef_bg} alt="" width='60%' className='banner-home-img' />
                </div>

            </div>

            <div className="row m-0">
                <div className="col-md-8 px-0 my-2">

                    <div className="row m-0 home-banner p-2 py-4">
                        <div className="col-md-4 text-center">
                            <h4 className='m-0'>{overview?.nbr_repas || 0}</h4>
                            <span class="badge bg-warning text-dark">Repas</span>
                        </div>
                        <div className="col-md-4 text-center dvdr-l-gray">
                            <h4 className='m-0'>{overview?.nbr_cmd_att_ram || 0}/{overview?.nbr_cmd_cours || 0}</h4>
                            <span class="badge bg-warning text-dark">Commandes</span>
                        </div>
                        <div className="col-md-4 text-center dvdr-l-gray">
                            <h4 className='m-0'>{overview?.nbr_cmd_debts || 0}</h4>
                            <span class="badge bg-warning text-dark">Non Payé</span>
                        </div>
                    </div>

                    <div className="row m-0 home-banner p-2 mt-2">
                        <div className="col-md-12 pt-3">
                            <h6 className='fnt-w7 m-0'><FontAwesomeIcon icon={['fas', 'truck-loading']} /> Commandes récentes</h6>
                            <hr className='mt-1' />
                        </div>

                        {
                            Array.isArray(overview?.last_cmd) && overview?.last_cmd?.map(el =>
                                <div className="col-md-12 home-banner p-2 my-1">
                                    <div className="row m-0">
                                        <div className="col-md-4 fnt-sm">
                                            <span className='d-block text-muted'>Réf</span>
                                            <span className='d-block fnt-w5'>#{el?.ref}</span>
                                        </div>
                                        <div className="col-md-4 fnt-sm dvdr-l-gray">
                                            <span className='d-block text-muted'>Nombre de repas</span>
                                            <span className='d-block fnt-w5'>{Array.isArray(el?.chef_order_bags) ? el?.chef_order_bags.length : 0}</span>
                                        </div>
                                        <div className="col-md-4 fnt-sm dvdr-l-gray">
                                            <span className='d-block text-muted'>Etats</span>
                                            <span class="badge bg-warning text-dark fnt-w5 fnt-xsm">{el?.status}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }




                    </div>

                </div>

                <div className="col-md-4 px-0 ps-1 my-2">

                    <div className="row m-0 home-banner p-2 ">
                        <div className="col-md-12 pt-3">
                            <h6 className='fnt-w7 m-0'><FontAwesomeIcon icon={['fas', 'utensils']} /> Top Vente</h6>
                            <hr className='mt-1' />
                        </div>

                        {
                            Array.isArray(overview?.top_repas) && overview?.top_repas?.map(el =>
                                <div className="col-md-12 home-banner p-2 my-1">
                                    <div className="row m-0">
                                        <div className="col-md-2 fnt-sm px-0">
                                            <img src={`${api_file_url_product}${el?.img}`} alt="" width='100%' />
                                        </div>
                                        <div className="col-md-10 fnt-sm dvdr-l-gray py-2">
                                            <span className='d-block fnt-w5'>{el?.nom}</span>
                                            <hr className='m-1' />
                                            <span className='d-block text-muted '>Vendus <span class="badge bg-warning text-dark">{el?.nbr_vendu}</span></span>

                                        </div>
                                    </div>
                                </div>
                            )
                        }












                    </div>

                </div>
            </div>
        </div>
    );
};

export default Home;