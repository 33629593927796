import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence } from "framer-motion"
import { Wizard, useWizard } from 'react-use-wizard';
import Header from './SubscribeStepperHeader'
import Footer from './SubscribeStepperFooter'
import StepAbnSel from './StepAbnSel'
import StepRepasSel from './StepRepasSel'
import StepConfirmation from './StepConfirmation'

import { loadLoggedUser, login, registerClient } from '../../../slices/auth.slice';
import { listPublicProduct } from '../../../slices/product.slice';
import { listPublicSubscription } from '../../../slices/subscription.slice';
import { placeByClient } from '../../../slices/order.slice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const FormuleContainer = () => {

    const { loggedUser } = useSelector(state => state.auth_store);
    const { loading, error } = useSelector(state => state.order_store);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [order, set_order] = useState([])

    const [lst_abn, set_lst_abn] = useState([])
    const [sel_abn, set_sel_abn] = useState()
    const [sel_repas, set_sel_repas] = useState([])
    const previousStepRef = useRef(0);


    const load_data = () => {
        dispatch(listPublicSubscription()).unwrap().then(res_sub => {
            dispatch(listPublicProduct()).unwrap().then(res_prod => {
                if (Array.isArray(res_sub?.rows)) {
                    set_lst_abn(res_sub?.rows)
                    if (res_sub?.rows.length > 1) {
                        set_sel_abn(res_sub?.rows[1])
                    } else if (res_sub?.rows.length > 0) {
                        set_sel_abn(res_sub?.rows[0])
                    }
                }

                if (Array.isArray(res_prod?.rows))
                    set_sel_repas(res_prod?.rows.map(el => ({ ...el, quantity: 0 })))

                dispatch(loadLoggedUser())
            })
        })
    }

    const onLogin = (data) => {
        dispatch(login(data))
    }

    const onRegister = (data) => {
        dispatch(registerClient(data))
    }

    const onOrder = (data) => {
        if (loggedUser && data && sel_abn && sel_repas) {
            if (Array.isArray(sel_repas) && sel_repas.length > 0) {
                let orderbags = sel_repas.filter(el => el?.quantity > 0).map(el => ({ productId: el?.id, quantity: el?.quantity }))
                let order = { ...data, orderbags: orderbags, subscriptionId: sel_abn?.id }
                dispatch(placeByClient(order)).unwrap().then(res => {
                    if (res?.done)
                        navigate(`/app/order-pay/${res?.order}`)
                })
            }
        }
    }

    useEffect(() => {
        load_data()
    }, [])

    return (
        <div className="row m-0 p-3 pt-1">
            <Wizard
                header={<Header sel_abn={sel_abn} sel_repas={sel_repas} />}
                footer={<Footer />}
                wrapper={<AnimatePresence initial={false} exitBeforeEnter />}>

                <StepAbnSel previousStep={previousStepRef} lst_abn={lst_abn} sel_abn={sel_abn} set_sel_abn={set_sel_abn} />
                <StepRepasSel previousStep={previousStepRef} sel_repas={sel_repas} sel_abn={sel_abn} set_sel_repas={set_sel_repas} />

                <StepConfirmation previousStep={previousStepRef} sel_repas={sel_repas} sel_abn={sel_abn}
                    loggedUser={loggedUser} loading={loading} error={error} onLogin={onLogin} onRegister={onRegister} onOrder={onOrder} />

            </Wizard>
        </div>
    );
};

export default FormuleContainer;