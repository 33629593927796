export const getProducts = () => {
    let data = JSON.parse(localStorage.getItem('lst-prd'));
    return data
}

export const addProduct = (el) => {
    let data = JSON.parse(localStorage.getItem('lst-prd'));
    if(!Array.isArray(data))
    data = []

    data = [...data , {...el , id : (data.length +1) }]
    localStorage.setItem('lst-prd', JSON.stringify(data));
}

export const editProduct = (p) => {
    let data = JSON.parse(localStorage.getItem('lst-prd'));
    if(!Array.isArray(data))
    data = []

    data = data.map(el => el?.id == p?.id ? p : el )
    localStorage.setItem('lst-prd', JSON.stringify(data));
}

export const delProduct = (p) => {
    let data = JSON.parse(localStorage.getItem('lst-prd'));
    if(!Array.isArray(data))
    data = []

    data = data.filter(el => el?.id != p?.id )
    localStorage.setItem('lst-prd', JSON.stringify(data));
}