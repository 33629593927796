import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';
import Api from '../services/course.service';


export const listPendingRamassage = createAsyncThunk(
    "course-ramassage/adm-list-pending",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listPendingRamassage(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createCourseRam = createAsyncThunk(
    "course-ramassage/adm-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createCourseRam(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const viewCourseRam = createAsyncThunk(
    "course-ramassage/adm-view",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.viewCourseRam(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const validateCourseRam = createAsyncThunk(
    "course-ramassage/adm-validate",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.validateCourseRam(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const deleteCourseRam = createAsyncThunk(
    "course-ramassage/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteCourseRam(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const listCourseRam = createAsyncThunk(
    "course-ramassage/adm-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listCourseRam(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)










export const listPendingLiv = createAsyncThunk(
    "course-Liv/adm-list-pending",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listPendingLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createCourseLiv = createAsyncThunk(
    "course-Liv/adm-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createCourseLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const viewCourseLiv = createAsyncThunk(
    "course-Liv/adm-view",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.viewCourseLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const validateCourseLiv = createAsyncThunk(
    "course-Liv/adm-validate",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.validateCourseLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const deleteCourseLiv = createAsyncThunk(
    "course-Liv/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteCourseLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const listCourseLiv = createAsyncThunk(
    "course-Liv/adm-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listCourseLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)






export const listCourseRamByLiv = createAsyncThunk(
    "course-Ram/liv-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listCourseRamByLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const listCourseLivByLiv = createAsyncThunk(
    "course-Liv/liv-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listCourseLivByLiv(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)




const initialState = {
    loading: false,
    error: '',
}

const courseSlice = createSlice({
    name: "course",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },

    },
    extraReducers: builder => {
        builder
            .addMatcher(isFullfieldAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
            })
            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })



    }
})

const { reducer } = courseSlice;
export const { clear } = courseSlice.actions;
export default reducer;