import React, { useState, useEffect } from 'react';
import logo_text from '../../../assets/img/logo-text.png';
import slider_bg from '../../../assets/img/slider-bg.png';

import ic_salad from '../../../assets/img/salad.png';
import ic_delivery from '../../../assets/img/shipped.png';
import ic_microwave from '../../../assets/img/oven.png';
import ic_food from '../../../assets/img/restaurant.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { api_file_url_product } from '../../../config/Params.constant';
import { listPublicProduct } from '../../../slices/product.slice';
import { useDispatch } from "react-redux";

const Home = () => {

    const dispatch = useDispatch();
    const [lst_repas, set_lst_repas] = useState([])

    const load_data = () => {
        dispatch(listPublicProduct()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_repas(res?.rows)
            }
        })
    }

    useEffect(() => { load_data() }, [])
    return (
        <>
            <div className="row m-0 bg-grd-yellow-hard slider-container">

                <div className="col-md-4 pt-200 ps-5">
                    {/* <h1 className='m-0 fnt-wb ps-5 ms-2'>MAKELTI</h1> */}
                    <img src={logo_text} className='ms-5 shakable' width='285' alt="" />
                    <h5 className='m-0 ps-5 ms-2 fnt-w7 ' style={{ color: '#000000ad' }}>GOOD FOOD , GOOD MOOD</h5>
                    <h4 className='m-0 fnt-wb mt-3 ps-5 ms-2'>REPAS FAIT MAISON</h4>
                    <p className='m-0 ps-5 ms-2'>CUISINE TUNISIENNE , ALGERIENNE , MAROCAINE ...</p>
                    <h4 className='m-0 fnt-wb mt-3 ps-5 ms-2'>LIVRAISON GRATUITE EN 72H/48H</h4>
                    <p className='m-0 ps-5 ms-2'>LE JOUR DE MON CHOIX, DU MARDI AU SAMEDI</p>

                    <div className="col-md-12 ps-5 mt-4">
                        <button className="btn btn-dark ms-2 px-4 shakable"><FontAwesomeIcon className='me-3' icon={['fas', 'shopping-cart']} /> JE M’ABONNE <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                    </div>

                </div>


                <div className="col-md-8 pt-200 text-end position-relative">
                    <img src={slider_bg} alt="" width="50%" className='slider-img' />
                </div>
            </div>


            <div className="row m-0 p-3 px-5" style={{ background: '#231f1e' }}>
                <div className="row m-0 p-3 px-5" style={{ background: '#231f1e' }}>
                    <div className="col-md-12 text-center my-3">
                        <h2 className='fnt-wb txt-yellow'>
                            <figcaption className="blockquote-footer txt-yellow">
                                COMMENT ÇA MARCHE ?
                            </figcaption>

                        </h2>
                    </div>

                    <div className="col-md-3 text-center px-5">
                        <img src={ic_salad} alt="" width="70" />
                        <h4 className='m-0 txt-yellow mt-3'>JE CHOISIS</h4>
                        <p className='text-light mt-0'>ma formule selon mes besoins et mes repas selon mes envies et allergènes</p>
                    </div>



                    <div className="col-md-3 text-center px-5">
                        <img src={ic_delivery} alt="" width="70" />
                        <h4 className='m-0 txt-yellow mt-3'>JE REÇOIS</h4>
                        <p className='text-light mt-0'>ma formule selon mes besoins et mes repas selon mes envies et allergènes</p>
                    </div>


                    <div className="col-md-3 text-center px-5">
                        <img src={ic_microwave} alt="" width="70" />
                        <h4 className='m-0 txt-yellow mt-3'>JE DÉGUSTE</h4>
                        <p className='text-light mt-2'>ma formule selon mes besoins et mes repas selon mes envies et allergènes</p>
                    </div>


                    <div className="col-md-3 text-center px-5">
                        <img src={ic_food} alt="" width="70" />
                        <h4 className='m-0 txt-yellow mt-3'>JE CONTINUE</h4>
                        <p className='text-light mt-2'>ma formule selon mes besoins et mes repas selon mes envies et allergènes</p>
                    </div>



                </div>
            </div>





            <div className="row m-0 p-3 px-5 py-5" style={{ background: '#fff' }}>
                <div className="row m-0 p-3 px-5" style={{ background: '#fff' }}>
                    <div className="col-md-12 text-center my-3">
                        <h2 className='fnt-wb '>
                            <figcaption className="blockquote-footer " style={{ color: "#000" }}>
                                LES PLATS POPULAIRE DE LA SEMAINE
                            </figcaption>

                        </h2>
                    </div>

                    {Array.isArray(lst_repas) && lst_repas.map(el =>
                        <div className="col-md-3" key={`crd-prd-${el?.id}`}>
                            <div className="col-md-12 prod-card p-0 my-1">

                                <div className="row m-0 p-0">



                                    <div className="col-md-12 p-0">
                                        <img src={el?.img ? `${api_file_url_product}${el?.img}` : ``} alt="" width="100%" style={{ maxHeight: 321 }} />
                                    </div>

                                    <div className="col-md-12 py-1 text-center">
                                        <b className='fnt-wb d-block' style={{ minHeight: 60 }}>{el?.nom}</b>
                                        <p className='fnt-sm m-0' style={{ minHeight: 60 }}>{el?.description}</p>
                                    </div>
                                    <hr className='my-1' />

                                    <div className="col-md-12 py-2">
                                        <span className='d-block fnt-sm fnt-w5'>Cuisine <span className="badge bg-dark fnt-w4 float-end">{el?.cuisine}</span></span>

                                    </div>
                                    <div className="col-md-12 py-2">
                                        <span className='d-block fnt-sm fnt-w5'>Categorie <span className="badge bg-dark fnt-w4 float-end">{el?.category}/{el?.sub_category}</span></span>

                                    </div>




                                </div>
                            </div>
                        </div>
                    )}



                </div>
            </div>
        </>
    );
};

export default Home;