import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';

const UserPasswordForm = ({ loading, error, onUpdate, show, onClose, sel_item, ...props }) => {

    const roles = ['admin', 'chef', 'livreur']
    const validationSchema = Yup.object().shape({
        password: Yup.string().min(6, 'password not valid !').required('Ce champ est obligatoire !'),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !').required('Ce champ est obligatoire !'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        if (sel_item) {
            onUpdate({ ...sel_item, password: data?.password })
            reset()
        }
    }

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'lock']} />
                    </b>
                    {`Réinitialisation du mot de passe :  ${sel_item?.nom} ${sel_item?.prenom}`}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                        <div className="form-group col-md-6">
                            <label className="fnt-w7 fnt-sm">Mot de passe</label>
                            <input type="password" placeholder="password ..."  {...register("password")} className={`form-control form-control-sm ${errors.password ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.password?.message}</p>
                        </div>
                        <div className="form-group col-md-6">
                            <label className="fnt-w7 fnt-sm">Confirmation Mot de passe</label>
                            <input type="password" placeholder="password ..."  {...register("confirm_password")} className={`form-control form-control-sm ${errors.password ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.confirm_password?.message}</p>
                        </div>

                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default UserPasswordForm;