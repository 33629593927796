import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listPendingLiv, listCourseLiv } from '../../../slices/course.slice';
import { api_file_url_product } from '../../../config/Params.constant';
import { courseLiv_status } from '../../../utils/Enums.helper';

import Badge from 'react-bootstrap/Badge';


const CoursesLivraison = () => {


    const { loading, error } = useSelector(state => state.course_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [nbr_pending, set_nbr_pending] = useState(0)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listCourseLiv()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }

            dispatch(listPendingLiv()).unwrap().then(res => {
                if (Array.isArray(res?.rows)) {
                    set_nbr_pending(res?.rows?.length)
                }
            })


        })
    }

    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row no-marg">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'truck']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Livraison <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>
                            <Link to='create'>
                                <button className='btn btn-success float-end' ><FontAwesomeIcon icon={['fas', 'plus']} />  Planifier une course <Badge bg="danger">{nbr_pending}</Badge></button>
                            </Link>

                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <CoursesLivList lst_order={lst_data} />
            </div>


        </div>
    );
};


const CoursesLivList = ({ lst_order, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'motorcycle']} />
                                </b>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Livreur</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.livreur?.nom || '---'} {el?.livreur?.prenom || '---'}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Tél</b>
                                <p className=' mb-1 fnt-sm fnt-w7 text-success'>{el?.livreur?.tel || '---'}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm d-block'>Etat</b>
                                {el?.status == courseLiv_status.EN_COURS ? <Badge bg='warning' className='text-dark'>{el?.status}</Badge> : <Badge bg='success'>{el?.status}</Badge>}
                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Nbr Commandes</b>
                                <p className='text-muted mb-1 fnt-sm'>{Array.isArray(el?.course_liv_bags) ? el?.course_liv_bags?.length : '---'}  </p>
                            </div>



                            <div className="col-md-1 text-end pt-1">
                                <Link to={el?.ref}>
                                    <button className='btn btn-sm btn-success w-100'><FontAwesomeIcon className='me-0' icon={['fas', 'long-arrow-alt-right']} /></button>
                                </Link>
                            </div>

                        </div>
                    </div>
                )
            }


        </div>
    );

}
export default CoursesLivraison;