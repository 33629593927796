import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ClientProfileForm = ({ loading, error, onUpdate, userProfile }) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Ce champ est obligatoire !'),
        nom: Yup.string().required('Ce champ est obligatoire !'),
        prenom: Yup.string().required('Ce champ est obligatoire !'),
        adresse: Yup.string().required('Ce champ est obligatoire !'),
        tel: Yup.string().required('Ce champ est obligatoire !'),
        zip: Yup.string().required('Ce champ est obligatoire !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        onUpdate(data)
    }

    useEffect(() => {
        if (userProfile) {
            setValue('email', userProfile?.email)
            setValue('nom', userProfile?.nom)
            setValue('prenom', userProfile?.prenom)
            setValue('adresse', userProfile?.adresse)
            setValue('tel', userProfile?.tel)
            setValue('zip', userProfile?.zip)
        }

    }, [userProfile])

    return (
        <div className="col-md-12 home-banner my-1 shadow-none" >
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }
            <div className="col-md-12 text-end p-1" >
                {loading ?
                    <div className="col-md-12 p-4 text-ctr">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <button type="submit" className="btn btn-dark w-auto  px-3" >Enregistrer <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                }
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="row m-0">
                <h5>Info du compte</h5>
                <hr />
                <div className="form-group col-md-4">
                    <label className="fnt-w7 fnt-sm">E-mail</label>
                    <input disabled  {...register("email")} className={`form-control form-control-sm ${errors.email ? 'is-invalid ' : 'form-control'}`} />
                    <p className="text-danger">{errors.email?.message}</p>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-md-4  form-group col-6">
                        <label className="fnt-w7 fnt-sm">Nom</label>
                        <input type="text" placeholder="Nom..." {...register("nom")} className={`form-control form-control-sm `} />
                        <p className="text-danger">{errors.nom?.message}</p>
                    </div>

                    <div className="col-md-4 form-group col-6">
                        <label className="fnt-w7 fnt-sm">Prénom</label>
                        <input type="text" placeholder="Prenom ..." {...register("prenom")} className={`form-control form-control-sm`} />
                        <p className="text-danger">{errors.prenom?.message}</p>
                    </div>
                </div>

                <h5>Info du livraison</h5>
                <hr />
                <div className="form-group col-md-8 mb-2">
                    <label className="fnt-w7 fnt-sm">Adresse</label>
                    <textarea rows="2" placeholder="Adresse..." {...register("adresse")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.adresse?.message}</p>
                </div>
                <div className="row m-0 p-0">
                    <div className="form-group col-md-2">
                        <label className="fnt-w7 fnt-sm">Zip</label>
                        <input placeholder="zip..." {...register("zip")} className={`form-control  form-control-sm`} />
                        <p className="text-danger">{errors.zip?.message}</p>
                    </div>

                    <div className="form-group col-md-4 col-6">
                        <label className="fnt-w7 fnt-sm">Tél</label>
                        <input placeholder="+33 ..."  {...register("tel")} className={`form-control form-control-sm`} />
                        <p className="text-danger">{errors.tel?.message}</p>
                    </div>
                </div>

                <h5>Sécurité</h5>
                <hr />

                <div className="row m-0 p-0">
                    <div className="col-md-4  form-group col-6">
                        <label className="fnt-w7 fnt-sm">Nouveau mot de passe</label>
                        <input type="text" placeholder="***********" {...register("password")} className={`form-control form-control-sm `} />
                        <p className="text-danger">{errors.password?.message}</p>
                    </div>

                    <div className="col-md-4 form-group col-6">
                        <label className="fnt-w7 fnt-sm">Confirmation Nouveau mot de passe</label>
                        <input type="text" placeholder="***********" {...register("confirmPassword")} className={`form-control form-control-sm`} />
                        <p className="text-danger">{errors.confirmPassword?.message}</p>
                    </div>

                    <div className="col-md-4 text-end pt-3">
                        <button type="button" className="btn btn-dark w-auto  px-3" >Changer <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>

                    </div>
                </div>





            </form>

        </div>
    );
};

export default ClientProfileForm;