import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listByAdm } from '../../../slices/order.slice';
import { api_file_url_product } from '../../../config/Params.constant';
import { user_roles } from '../../../utils/Enums.helper';


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';


const Orders = () => {


    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_item, set_sel_item] = useState()
    const [show_details, set_show_details] = useState(false)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listByAdm()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    const onViewDetails = value => {
        set_sel_item(value)
        set_show_details(true)
        history(`${value?.ref}`)
    }

    const onCloseDetails = () => {
        set_sel_item(null)
        set_show_details(false)
    }


    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row no-marg">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'truck-loading']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Commande clients <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>


                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>


            <div className="row m-0 mt-2">
                <OrderList lst_order={lst_data} onViewDetails={onViewDetails} />
            </div>



        </div>
    );
};


const OrderList = ({ lst_order, onViewDetails }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1'>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1 text-center pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'box']} />
                                </b>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                            </div>

                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Client</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.nom || '---'} {el?.prenom || '---'}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Date Liv</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.date_liv} {el?.heure_liv} </p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Adresse</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.adresse || '---'} </p>
                            </div>
                            <div className="col-md-1">
                                <b className='d-block txt-dark fnt-w7  fnt-sm'>Etats</b>
                                <span class="badge bg-warning text-dark fnt-w5 fnt-xsm ">{el?.status}</span>
                            </div>

                            <div className="col-md-2 text-end pt-1">
                                <button className='btn btn-sm btn-success px-3' onClick={e => onViewDetails(el)}><FontAwesomeIcon className='me-3' icon={['fas', 'eye']} /> Voir Plus</button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );

}
export default Orders;