import React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
    const {
        nextStep,
        previousStep,
        isLoading,
        isLastStep,
        isFirstStep,

    } = useWizard();

    return (
        <>
            <div className="col-md-12  mt-2 py-2">

            </div>
        </>
    );
}

export default Footer;