export const user_roles = {
    root: 'root',
    admin: 'admin',
    client: 'client',
    chef: 'chef',
    livreur: 'livreur',
}

export const client_order_status = {
    EN_ATTENTE: 'en-attente',
    EN_COURS: 'en-cours',
    PRET_A_LIVRER: 'pret-a-livrer',
    LIVRAISON_EN_COURS: 'livraison-en-cours',
    LIVRE: 'livre',
    ANNULER: 'annuler',
}

export const chef_order_status = {
    EN_COURS: 'en-cours',
    EN_ATTENTE_RAMASSAGE: 'en-attente-ramassage',
    RAMASSAGE_EN_COURS: 'ramassage-en-cours',
    RAMASSE: 'ramasse',
    PAYE: 'paye',
}
export const facture_status = {
    PAYE: 'paye',
    NON_PAYE: 'non-paye',
}


export const courseRam_status = {
    EN_COURS: 'en-cours',
    VALIDE: 'valide',
}

export const courseRamBag_status = {
    EN_COURS: 'en-cours',
    RAMASSE: 'ramasse',
    NON_RAMASSE: 'non-ramasse',
}

export const courseLiv_status = {
    EN_COURS: 'en-cours',
    VALIDE: 'valide',
}

export const courseLivBag_status = {
    EN_COURS: 'en-cours',
    LIVRE: 'livre',
    NON_LIVRE: 'non-livre',
}