import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { api_file_url_avatar } from '../../../config/Params.constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChefDetails = ({ chef, show, onClose, ...props }) => {
    return (
        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} style={{ width: '40%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'eye']} />
                    </b>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row m-0">
                    <div className="col-md-12 text-center">
                        <img src={`${api_file_url_avatar}${chef?.img}`} width='40%' alt="" style={{ borderRadius: 32 }} />
                        <h3 className='fnt-w7 my-4'>{chef?.nom} {chef?.prenom}</h3>
                        <p>{chef?.bio}</p>
                    </div>

                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default ChefDetails;