import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_file_url_product } from '../../../config/Params.constant';

import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

const OrderList = ({ lst_order, loading, error }) => {
    return (
        <>
            <div className="col-md-12 text-end py-2">
                <Link to={'/app/formule'}><button className='btn btn-dark'> <FontAwesomeIcon icon={['fas', 'plus']} /> Nouvelle Commande</button></Link>
            </div>
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 home-banner my-1 shadow-none mb-2' style={{ overflow: 'hidden' }}>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1">
                                <b className="icon-item-round mt-2">
                                    <FontAwesomeIcon icon={['fas', 'box']} />
                                </b>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                            </div>
                            <div className="col-md-1">
                                <b className='txt-dark fnt-w7  fnt-sm'>Formule</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.subscription?.nbr_repas} Repas</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Date de livraison</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.date_liv} {el?.heure_liv}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Frais de livraison</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.shipping} €</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Prix</b>
                                <p className='text-muted mb-1 fnt-sm'>{el?.total} €</p>
                            </div>
                            <div className="col-md-2">
                                <b className='d-block txt-dark fnt-w7  fnt-sm'>Etat</b>
                                <span class="badge bg-warning text-dark fnt-w5 fnt-xsm">{el?.status}</span>
                            </div>
                        </div>

                        {el?.status == 'incomplet' &&
                            <Alert variant='warning' className='no-border-rad'>
                                <b>Commande Incomplet : </b> Vous devez completer votre commande
                                <Link to={`/app/order-pay/${el?.ref}`}>
                                    <button className='btn btn-sm btn-dark float-end'>Completer <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                                </Link>
                            </Alert>
                        }

                        <label className='px-4 fnt-sm fnt-w7 p-2'>Les Repas :</label>
                        {
                            Array.isArray(el?.orderbags) &&
                            el?.orderbags.map(p =>
                                <div className="row m-0 px-5 py-2">
                                    <div className="col-md-1 p-0">
                                        <img src={`${api_file_url_product}${p?.product?.img}`} alt="" width='45' loading='lazy' />
                                    </div>
                                    <div className="col-md-7 py-2">
                                        <b>{p?.product?.nom}</b>
                                    </div>
                                    <div className="col-md-4 py-2">
                                        <b>X{p?.quantity}</b>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
};

export default OrderList;