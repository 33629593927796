import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../assets/img/logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

const NavBarMenu = ({ loggedUser, withShadow, onLogout }) => {
    return (
        <Navbar variant="light" className={`bg-grd-yellow-hard ${withShadow && 'v-shadow'}`}>
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="" className='menu-logo' style={{ transform: 'scale(2)' }} />
                </Navbar.Brand>
                <Nav className="">
                    <NavLink to='/home/menu' className='nav-link px-3'>Menu</NavLink>
                    <NavLink to='/home/formule' className='nav-link px-3'>Formules</NavLink>
                    <NavLink to='/home/faq' className='nav-link px-3'>FAQ</NavLink>
                    {
                        loggedUser ?
                            <>
                                <NavLink to='/app/espace' className='nav-link me-3'><FontAwesomeIcon icon={['fas', 'shopping-basket']} /></NavLink>
                                <Dropdown>
                                    <Dropdown.Toggle variant="dark" id="dropdown-user-tools">
                                        <FontAwesomeIcon icon={['fas', 'user']} />
                                        <span className='fnt-sm px-2'>{loggedUser?.nom}({loggedUser?.email})</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/app/espace">Mon espace</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={onLogout}>Se déconnecter</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            :
                            <>
                                <NavLink to='/login' className='nav-link me-3'><FontAwesomeIcon icon={['fas', 'shopping-basket']} /></NavLink>
                                <NavLink to='/login' className='nav-link nav-btn me-3'>Se connecter <FontAwesomeIcon className='ms-2' icon={['fas', 'sign-in-alt']} /></NavLink>
                                <NavLink to='/home/formule' className='nav-link nav-btn me-3'>Je m'abonne <FontAwesomeIcon className='ms-2' icon={['fas', 'long-arrow-alt-right']} /></NavLink>
                            </>
                    }

                </Nav>
            </Container>
        </Navbar>
    );
};

export default NavBarMenu;