import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewByAdm, dispatchByAdm, updateByAdm } from '../../../slices/order.slice';
import { api_file_url_product } from '../../../config/Params.constant';
import { client_order_status, chef_order_status } from '../../../utils/Enums.helper';


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';


const OrderDetails = () => {


    const { loading, error } = useSelector(state => state.order_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [order, set_order] = useState()
    const [nbr_ready, set_nbr_ready] = useState(0)
    const [err_ready, set_err_ready] = useState(0)

    const load_data = () => {
        dispatch(viewByAdm(params?.ref)).unwrap().then(res => {
            set_order(res?.entity)
        })
    }

    const onDispatchOrder = () => {
        if (order && order?.status === client_order_status.EN_ATTENTE) {
            dispatch(dispatchByAdm(params?.ref)).unwrap().then(res => {
                load_data()
            })
        }
    }

    const onSetReadyForLivOrder = () => {
        set_err_ready('')
        if (order) {
            if (Array.isArray(order?.chef_orders) && order?.status === client_order_status.EN_COURS) {
                let tmp = order?.chef_orders.filter(el => el?.status === chef_order_status.RAMASSE).length
                if (tmp != order?.chef_orders.length) {
                    set_err_ready(`Vous ne pouvez pas livrer cette commande , il reste ${+order?.chef_orders.length - +tmp} Sous-commande à ramassé !`)
                } else {
                    dispatch(updateByAdm({ id: order?.id, status: client_order_status.PRET_A_LIVRER })).unwrap().then(res => {
                        load_data()
                    })
                }
            }

        }
    }

    useEffect(() => {
        if (params?.ref)
            load_data()
    }, [params?.ref])

    useEffect(() => {
        if (order) {
            if (Array.isArray(order?.chef_orders)) {
                set_nbr_ready(order?.chef_orders.filter(el => el?.status === chef_order_status.EN_ATTENTE_RAMASSAGE).length)
            }
        }
    }, [order])



    return (
        <div className='col-md-12 p-2 pb-5'>

            <div className="row m-0 mt-2">
                {(error || err_ready) &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error || err_ready}</span>
                    </div>
                }
            </div>

            <div className="row m-0 mt-2">

                {
                    order &&
                    <div className='col-md-12 p-0 home-banner my-1' style={{ overflow: 'hidden' }}>

                        <div className="row m-0 pt-2 dvdr-b-gray">
                            <div className="col-md-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'box']} />
                                </b>
                            </div>

                            <div className="col-md-4">
                                <h5 className="d-inline-block txt-secondary pt-2 mb-0 ">Commande clients N° <span className='fnt-w7 text-success'>{order?.ref}</span></h5>
                            </div>

                            <div className="col-md-2">
                                <b className='d-block txt-dark fnt-w7  fnt-sm'>Etat</b>
                                <span class="badge bg-warning text-dark fnt-w5 fnt-sm">{order?.status}</span>
                            </div>
                            <div className="col-md-3">
                                <b className='d-block txt-dark fnt-w7  fnt-sm'>Sous-Commandes</b>
                                {
                                    Array.isArray(order?.chef_orders) &&
                                    <>
                                        <span class="badge bg-success fnt-w5 fnt-sm">{nbr_ready}/{order?.chef_orders.length}</span> <span className='text-muted fnt-sm'>prêt à ramassée</span>
                                    </>
                                }
                            </div>
                            <div className="col-md-2 text-end p-2">
                                {
                                    order?.status === client_order_status.EN_ATTENTE &&
                                    <button className='btn btn-sm btn-success' disabled={loading} onClick={onDispatchOrder}><FontAwesomeIcon className='me-2' icon={['fas', 'check-circle']} /> Dispatcher</button>
                                }

                                {
                                    order?.status === client_order_status.EN_COURS &&
                                    <button className='btn btn-sm btn-success' disabled={loading} onClick={onSetReadyForLivOrder}><FontAwesomeIcon className='me-2' icon={['fas', 'check-circle']} /> Prét Pour la livraison</button>
                                }
                            </div>
                            <hr className='mt-2' />
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Client</b>
                                <p className='text-muted mb-1 fnt-sm'>{order?.nom || '---'} {order?.prenom || '---'}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Date Liv</b>
                                <p className='text-muted mb-1 fnt-sm'>{order?.date_liv} {order?.heure_liv} </p>
                            </div>
                            <div className="col-md-2">
                                <b className='txt-dark fnt-w7  fnt-sm'>Adresse</b>
                                <p className='text-muted mb-1 fnt-sm'>{order?.adresse || '---'} </p>
                            </div>


                        </div>
                        {
                            Array.isArray(order?.orderbags) &&
                            order.orderbags.map(p =>
                                <div className="row m-0 px-5 py-2">
                                    <div className="col-md-1 p-0">
                                        <img src={`${api_file_url_product}${p?.product?.img}`} alt="" width='45' />
                                    </div>
                                    <div className="col-md-8 py-2">
                                        <b>{p?.product?.nom}</b>
                                    </div>
                                    <div className="col-md-3 py-2">
                                        <b>X{p?.quantity}</b>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                }
            </div>


            <div className="row m-0 mt-2">
                <h5 className='my-3'>Les sous-commandes <span class="badge bg-success fnt-w5 fnt-sm">{Array.isArray(order?.chef_orders) ? order?.chef_orders.length : '--'}</span></h5>

                {
                    Array.isArray(order?.chef_orders) && order?.chef_orders.map(oc =>
                        <div className='col-md-12 p-0 home-banner my-1' style={{ overflow: 'hidden' }}>

                            <div className="row m-0 pt-2 dvdr-b-gray">
                                <div className="col-md-1">
                                    <b className="icon-item-round">
                                        <FontAwesomeIcon icon={['fas', 'box']} />
                                    </b>
                                </div>

                                <div className="col-md-3">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                    <p className='text-muted mb-1 fnt-sm'>#{oc?.ref || '---'}</p>
                                </div>
                                <div className="col-md-3">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Chef</b>
                                    <p className='text-muted mb-1 fnt-sm'>{oc?.orderChef?.nom || '---'} {oc?.orderChef?.prenom || '---'}</p>
                                </div>
                                <div className="col-md-3">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Tel</b>
                                    <p className='text-muted mb-1 fnt-sm'>{oc?.orderChef?.tel || '---'}</p>
                                </div>
                                <div className="col-md-2">
                                    <b className='d-block txt-dark fnt-w7  fnt-sm'>Etat</b>
                                    <span class="badge bg-warning text-dark fnt-w5 fnt-sm">{oc?.status}</span>
                                </div>



                            </div>
                            {
                                Array.isArray(oc?.chef_order_bags) &&
                                oc.chef_order_bags.map(p =>
                                    <div className="row m-0 px-5 py-2">
                                        <div className="col-md-1 p-0">
                                            <img src={`${api_file_url_product}${p?.product?.img}`} alt="" width='45' />
                                        </div>
                                        <div className="col-md-8 py-2">
                                            <b>{p?.product?.nom}</b>
                                        </div>
                                        <div className="col-md-3 py-2">
                                            <b>X{p?.quantity}</b>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>



        </div>
    );
};



export default OrderDetails;