import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { motion } from "framer-motion"
import { Wizard, useWizard } from 'react-use-wizard';
import ClientForm from '../../../ui/form/client.form';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoginForm from '../../../ui/form/login.form';
import OrderForm from '../../../ui/form/order.form';


const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 800 : -800,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 800 : -800,
            opacity: 0,

        };
    },
};

const StepRepaSel = ({ sel_repas, sel_abn, loggedUser, onLogin, onRegister, onOrder, loading, error, previousStep }) => {

    const { activeStep, goToStep, handleStep, nextStep } = useWizard();
    let box = document.querySelector('#product-slider');
    const [order_list, set_order_list] = useState([])

    useEffect(() => {
        return () => {
            previousStep.current = activeStep;
        };
    }, [activeStep, previousStep]);

    useEffect(() => {
        if (Array.isArray(sel_repas) && sel_repas.length > 0) {
            set_order_list(sel_repas.filter(el => el?.quantity > 0))
        }
    }, [sel_repas]);



    return (

        <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
                duration: .1
            }}
            custom={activeStep - previousStep.current}>

            <div className="row m-0 py-0" >

                <div className="col-md-12 text-center my-4">
                    <h2 className='fnt-wb '>
                        <figcaption className="blockquote-footer " style={{ color: "#000" }}>
                            RÉCAPITULATIF DE MA COMMANDE
                        </figcaption>

                    </h2>
                </div>


            </div>

            <div className="row m-0 px-5">

                <div className="col-md-7 no-x-padd-mobile">
                    <div className="row m-0">
                        <h3 className="fnt-w7 position-relative d-inline-block mt-3 mb-3">Infos du compte</h3>
                        {loggedUser ?
                            <>
                                <OrderForm loggedUser={loggedUser} loading={loading} error={error} onOrder={onOrder} />
                            </>
                            :
                            <Tabs
                                defaultActiveKey="register"
                                id="tabs-info-compte"
                                className="mb-3"
                            >
                                <Tab eventKey="register" title="S'inscrire">
                                    <ClientForm loading={loading} error={error} onRegister={onRegister} />
                                </Tab>
                                <Tab eventKey="login" title="Se connecter">
                                    <LoginForm loading={loading} error={error} onLogin={onLogin} />
                                </Tab>
                            </Tabs>
                        }
                    </div>

                </div>


                <div className="col-md-5">

                    <h3 className="fnt-w7 position-relative d-inline-block mt-3 mb-3">Ma séléction</h3>


                    <table className="table">
                        <thead><tr><th scope="col" className="w-75">REPAS</th><th scope="col" className="w-25">QUANTITE</th></tr></thead>
                        <tbody>
                            {
                                Array.isArray(order_list) && order_list.map(el =>
                                    <tr>
                                        <td className="fnt-w5 fnt-sm">{el?.nom} </td>
                                        <td className="fnt-w5">x{el?.quantity}</td>
                                    </tr>
                                )
                            }
                            <tr><td colspan="1" className="fnt-w7">MA FORMULE</td><td>{sel_abn?.nbr_repas} REPAS </td></tr><tr><td colspan="1" className="fnt-w7">PRIX</td><td>{sel_abn?.price} €</td></tr><tr style={{ borderTop: '2px solid rgb(0, 0, 0)' }}><td colspan="1" className="fnt-w7">TOTAL</td><td className="fnt-w5">{sel_abn?.price} €</td></tr></tbody></table>
                </div>
            </div>
        </motion.div>
    );
};

export default StepRepaSel;