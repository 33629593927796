import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class ProductService {

    listPublicProduct() {
        return axios.get(`${api_url}/public/product/list`);
    }

    uploadImage(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/public/product/upload`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    create(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/chef/product/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    update(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/chef/product/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    delete(data) {
        let token = storage.getToken();
        return axios.get(`${api_url}/chef/product/delete/${data?.ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listByChef() {
        let token = storage.getToken();
        return axios.get(`${api_url}/chef/product/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listByAdm() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/product/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    updateByAdm(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/product/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

}

export default new ProductService()