import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class UserService {

    listByAdm() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/user/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    listLivreurByAdm() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/user/liv/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    createChef(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/user/register-chef`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    createLivreur(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/user/register-livreur`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    createAdm(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/user/register-adm`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    updateUser(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/user/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getOverviewByChef() {
        let token = storage.getToken();
        return axios.get(`${api_url}/chef/overview/view`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getOverviewByAdm() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/overview/view`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


}

export default new UserService()