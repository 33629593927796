import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listByChef } from '../../../slices/facture.slice';
import { api_file_url_product } from '../../../config/Params.constant';
import { chef_order_status } from '../../../utils/Enums.helper';
import FactureDetails from './FactureDetails';


const FactureChef = () => {


    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_item, set_sel_item] = useState()
    const [show_details, set_show_details] = useState(false)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listByChef()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    const onViewDetails = value => {
        set_sel_item(value)
        set_show_details(true)
    }

    const onCloseDetails = () => {
        set_sel_item(null)
        set_show_details(false)
    }


    useEffect(() => {
        load_data()
    }, [])

    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row no-marg">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Mes Factures <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>


                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>


            <div className="row m-0 mt-2">

                {
                    Array.isArray(lst_data) && lst_data.map(el =>
                        <div className='col-md-12 p-0 home-banner my-1' style={{ overflow: 'hidden' }}>

                            <div className="row m-0 pt-2 dvdr-b-gray">
                                <div className="col-md-1 text-center pt-1">
                                    <b className="icon-item-round">
                                        <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} />
                                    </b>
                                </div>
                                <div className="col-md-2">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Ref</b>
                                    <p className='text-muted mb-1 fnt-sm'>#{el?.ref}</p>
                                </div>

                                <div className="col-md-2">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Prix/Plats</b>
                                    <p className='text-muted mb-1 fnt-sm'>{el?.prix_unitaire} €</p>
                                </div>
                                <div className="col-md-2">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Plats</b>
                                    <p className='text-muted mb-1 fnt-sm'>{el?.quantity_total} </p>
                                </div>
                                <div className="col-md-2">
                                    <b className='txt-dark fnt-w7  fnt-sm'>Prix Total</b>
                                    <p className='text-muted mb-1 fnt-sm'>{el?.prix_total} €</p>
                                </div>
                                <div className="col-md-1">
                                    <b className='d-block txt-dark fnt-w7  fnt-sm'>Etat</b>
                                    <span class="badge bg-warning text-dark fnt-w5 fnt-xsm">{el?.status}</span>
                                </div>

                                <div className="col-md-2 text-end pt-1">
                                    <button className='btn btn-sm btn-success px-3' onClick={e => onViewDetails(el)}><FontAwesomeIcon className='me-3' icon={['fas', 'eye']} /> Voir Plus</button>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>

            <FactureDetails item={sel_item} show={show_details} onClose={onCloseDetails} />

        </div>
    );
};

export default FactureChef;