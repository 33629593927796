import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

const UserForm = ({ loading, error, onRegister, onUpdate, show, onClose, sel_item, is_edit, ...props }) => {

    const roles = ['admin', 'chef', 'livreur']
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Ce champ est obligatoire !'),
        password: (is_edit && sel_item) ? Yup.string() : Yup.string().min(6, 'password not valid !').required('Ce champ est obligatoire !'),
        confirm_password: (is_edit && sel_item) ? Yup.string() : Yup.string().oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !').required('Ce champ est obligatoire !'),
        nom: Yup.string().required('Ce champ est obligatoire !'),
        prenom: Yup.string().required('Ce champ est obligatoire !'),
        adresse: Yup.string().required('Ce champ est obligatoire !'),
        tel: Yup.string().required('Ce champ est obligatoire !'),
        zip: Yup.string().required('Ce champ est obligatoire !'),
        role: Yup.string().required('Ce champ est obligatoire !'),
        isActif: Yup.boolean().nullable(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (is_edit && sel_item) {
            let tmp = { ...data, id: sel_item?.id }
            delete tmp?.password
            delete tmp?.confirm_password
            onUpdate(tmp)
        } else {
            onRegister(data)
        }
        reset()
    }

    useEffect(() => {
        if (is_edit && sel_item) {
            setValue('email', sel_item?.email)
            setValue('nom', sel_item?.nom)
            setValue('prenom', sel_item?.prenom)
            setValue('adresse', sel_item?.adresse)
            setValue('tel', sel_item?.tel)
            setValue('zip', sel_item?.zip)
            setValue('role', sel_item?.role)
            setValue('password', '')
            setValue('confirm_password', '')
        } else {
            reset()
        }
    }, [is_edit, sel_item])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        {(is_edit && sel_item) ?
                            <FontAwesomeIcon icon={['fas', 'user']} />
                            :
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                        }
                    </b>
                    {(is_edit && sel_item) ? `Profil ${sel_item?.nom} ${sel_item?.prenom}` : 'Nouveau Compte'}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">


                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Actif</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("isActif")}
                            />
                        </div>

                        <div className="form-group col-md-12">
                            <label className="fnt-w7 fnt-sm">Role</label>
                            <select disabled={is_edit}   {...register("role")} className={`form-control form-control-sm ${errors.role ? 'is-invalid ' : 'form-control'}`} >
                                <option disabled selected value=''>Selectioner le type de compte</option>
                                {
                                    roles.map(el => <option value={el} key={`opt-${el}`}>{el}</option>)
                                }
                            </select>
                            <p className="text-danger">{errors.email?.role}</p>
                        </div>



                        <div className="form-group col-md-12">
                            <label className="fnt-w7 fnt-sm">E-mail</label>
                            <input disabled={is_edit} placeholder="e-mail ..."  {...register("email")} className={`form-control form-control-sm ${errors.email ? 'is-invalid ' : 'form-control'}`} />
                            <p className="text-danger">{errors.email?.message}</p>
                        </div>

                        <div className="col-md-6 form-group col-6">
                            <label className="fnt-w7 fnt-sm">Nom</label>
                            <input type="text" placeholder="Nom..." {...register("nom")} className={`form-control form-control-sm '}`} />
                            <p className="text-danger">{errors.nom?.message}</p>
                        </div>

                        <div className="col-md-6 form-group col-6">
                            <label className="fnt-w7 fnt-sm">Prénom</label>
                            <input type="text" placeholder="Prenom ..." {...register("prenom")} className={`form-control form-control-sm'}`} />
                            <p className="text-danger">{errors.prenom?.message}</p>
                        </div>
                        {!is_edit && !sel_item &&
                            <>
                                <div className="form-group col-md-6">
                                    <label className="fnt-w7 fnt-sm">Mot de passe</label>
                                    <input type="password" placeholder="password ..."  {...register("password")} className={`form-control form-control-sm ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.password?.message}</p>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="fnt-w7 fnt-sm">Confirmation Mot de passe</label>
                                    <input type="password" placeholder="password ..."  {...register("confirm_password")} className={`form-control form-control-sm ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.confirm_password?.message}</p>
                                </div>
                            </>
                        }

                        <div className="form-group col-md-8 col-6">
                            <label className="fnt-w7 fnt-sm">Tél</label>
                            <input placeholder="+33 ..."  {...register("tel")} className={`form-control form-control-sm'}`} />
                            <p className="text-danger">{errors.tel?.message}</p>
                        </div>

                        <div className="form-group col-md-12 mb-2">
                            <label className="fnt-w7 fnt-sm">Adresse</label>
                            <textarea rows="2" placeholder="Adresse..." {...register("adresse")} className={`form-control form-control-sm'}`} />
                            <p className="text-danger">{errors.adresse?.message}</p>
                        </div>

                        <div className="form-group col-md-4">
                            <label className="fnt-w7 fnt-sm">ZIP *</label>
                            <input placeholder="zip..." {...register("zip")} className={`form-control  form-control-sm'}`} />
                            <p className="text-danger">{errors.zip?.message}</p>
                        </div>







                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default UserForm;