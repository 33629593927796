import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewByClient } from '../../../slices/order.slice';


import { PaymentElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripe_pk } from '../../../config/Params.constant';
const stripePromise = loadStripe(stripe_pk);

const PaymentContainer = () => {
    const [clientSecret, setClientSecret] = useState(null)
    const [order, setOrder] = useState(null)

    const { loading, error } = useSelector(state => state.order_store);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const load_data = () => {
        if (params?.ref) {
            dispatch(viewByClient(params?.ref)).unwrap().then(res => {
                if (res?.entity) {
                    setOrder(res?.entity)

                    if (res?.entity?.payClientSecret) {
                        setClientSecret(res?.entity?.payClientSecret)
                    }
                }
            })
        }
    }

    useEffect(() => {
        load_data()
    }, [params?.ref])

    useEffect(() => {
        console.log(clientSecret)
    }, [clientSecret])


    return (
        <div className='row m-0 my-5 px-5'>

            <div className="col-md-12 brd-btm-gray my-3">
                <h1>Paiement de la commande</h1>
            </div>
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }

            <div className="col-md-6">
                {
                    order && stripePromise && clientSecret &&
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <PaymentForm order={order} />
                    </Elements>
                }
            </div>

            <div className="col-md-6">

                <h3 className="fnt-w7 position-relative d-inline-block mt-3 mb-3">Ma commande</h3>


                <table className="table">
                    <thead><tr><th scope="col" className="w-75">REPAS</th><th scope="col" className="w-25">QUANTITE</th></tr></thead>
                    <tbody>
                        {
                            Array.isArray(order?.orderbags) && order?.orderbags.map(el =>
                                <tr>
                                    <td className="fnt-w5 fnt-sm">{el?.product?.nom} </td>
                                    <td className="fnt-w5">x{el?.quantity}</td>
                                </tr>
                            )
                        }
                        <tr><td colspan="1" className="fnt-w7">MA FORMULE</td><td>{order?.subscription?.nbr_repas} REPAS </td></tr><tr><td colspan="1" className="fnt-w7">PRIX</td><td>{order?.subscription?.price} €</td></tr><tr style={{ borderTop: '2px solid rgb(0, 0, 0)' }}><td colspan="1" className="fnt-w7">TOTAL</td><td className="fnt-w5">{order?.total} €</td></tr></tbody></table>
            </div>

        </div>
    );
};

const PaymentForm = ({ order }) => {

    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false)
    const [message, set_message] = useState('')

    const onPay = () => {
        set_message('')
        setIsProcessing(true)
        if (!order || !stripe || !elements) {
            setIsProcessing(false)
            return;
        }

        stripe.confirmPayment({
            elements,
            confirmParams: { return_url: `${window?.location?.origin}/app/complete-order` },
            //redirect: 'if_required'
        }).then(res => {
            console.log('----------------------------Stripe res')
            console.log(res)
            console.log('----------------------------/Stripe res')
            setIsProcessing(false)

            if (res?.error) {
                set_message(res?.error?.message)
            }


        }).catch(err => {
            console.log(err)
            set_message(err?.message)
            setIsProcessing(false)
        })
    }

    return (
        <div className='row m-0'>

            {message &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{message}</span>
                </div>
            }

            <PaymentElement />

            <div className="col-md-12 text-end py-2">
                <button className='btn btn-success' disabled={isProcessing} onClick={onPay}>
                    <FontAwesomeIcon icon={['fas', 'check-circle']} className='me-2' />
                    {isProcessing ? 'En cours...' : `Payer ${order?.total} €`}
                </button>
            </div>
        </div>
    );
};

export default PaymentContainer;