import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrderForm = ({ loading, error, onOrder, loggedUser }) => {

    const validationSchema = Yup.object().shape({
        adresse: Yup.string().required('Ce champ est obligatoire !'),
        zip: Yup.string().required('Ce champ est obligatoire !'),
        tel: Yup.string().required('Ce champ est obligatoire !'),
        date_liv: Yup.string().required('Ce champ est obligatoire !'),
        heure_liv: Yup.string().required('Ce champ est obligatoire !'),


    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        onOrder(data)
    }

    useEffect(() => {
        console.log(loggedUser)
        if (loggedUser) {
            setValue('adresse', loggedUser?.adresse)
            setValue('zip', loggedUser?.zip)
            setValue('tel', loggedUser?.tel)
        }
    }, [loggedUser])

    return (
        <div className="col-md-12" >
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }

            <div className="row m-0">
                <b>
                    <FontAwesomeIcon icon={['fas', 'user']} />
                    <span className='fnt-sm px-2'>{loggedUser?.nom} {loggedUser?.prenom} ({loggedUser?.email})</span>
                </b>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="row m-0">
                <h5 className='text-muted'>Infos de livraison de mon commande</h5>
                <div className="form-group col-md-12 mb-2">
                    <label className="fnt-w7 fnt-sm">Adresse</label>
                    <textarea rows="2" placeholder="Adresse..." {...register("adresse")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.adresse?.message}</p>
                </div>

                <div className="form-group col-md-4">
                    <label className="fnt-w7 fnt-sm">Zip</label>
                    <input placeholder="zip..." {...register("zip")} className={`form-control  form-control-sm'}`} />
                    <p className="text-danger">{errors.zip?.message}</p>
                </div>

                <div className="form-group col-md-8 col-6">
                    <label className="fnt-w7 fnt-sm">Tél</label>
                    <input placeholder="+33 ..." {...register("tel")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.tel?.message}</p>
                </div>

                <div className="form-group col-md-6 col-6">
                    <label className="fnt-w7 fnt-sm">Date</label>
                    <input type='date' {...register("date_liv")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.date_liv?.message}</p>
                </div>
                <div className="form-group col-md-6 col-6">
                    <label className="fnt-w7 fnt-sm">Heure</label>
                    <input type='time' {...register("heure_liv")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.heure_liv?.message}</p>
                </div>






                <div className="col-md-12 text-center" >
                    {loading ?
                        <div className="col-md-12 p-4 text-ctr">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Confirmer <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                    }
                </div>

            </form>

        </div>
    );
};

export default OrderForm;