import React from 'react';
import logo_light from '../../assets/img/logo-light.svg';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterMenu = () => {
    return (
        <footer className="footer p-5 pb-0">
            <Container>
                <div className="row m-0">

                    <div className="col-md-2">
                        <img src={logo_light} width="50%" style={{ opacity: 1, transform: 'scale(2)' }} alt="" />
                    </div>

                    <div className="col-sm-2 col-6">
                        <b className="footer-title">SERVICES</b>
                        <a href="#" target="_blank" className="footer_links fnt-sm">FORMULES</a>
                        <a href="#" target="_blank" className="footer_links fnt-sm">LES REPAS</a>
                        <a href="#" target="_blank" className="footer_links fnt-sm">LES CHEFS</a>
                    </div>

                    <div className="col-sm-3 col-6 ">
                        <b className="footer-title">DÉCOUVREZ</b>
                        <a href="#" target="_blank" className="footer_links fnt-sm">QUI SOMMES NOUS</a>
                        <a href="#" target="_blank" className="footer_links fnt-sm">POLITIQUE DE CONFIDENTIALITÉ</a>
                    </div>
                    <div className="col-sm-2 col-6">
                        <b className="footer-title">AIDE</b>
                        <a href="#" target="_blank" className="footer_links fnt-sm">FAQ</a>

                        <b className="footer-title mt-3">SECURE PAYMENT</b>

                        <b className="d-inline-block me-2"> <span className="contact_icon"><FontAwesomeIcon icon={['fab', 'cc-visa']} /></span></b>

                        <b className="d-inline-block me-2"> <span className="contact_icon"><FontAwesomeIcon icon={['fab', 'cc-mastercard']} /></span></b>

                        <b className="d-inline-block me-2"> <span className="contact_icon"><FontAwesomeIcon icon={['fab', 'cc-paypal']} /></span></b>
                        <b className="d-inline-block me-2"> <span className="contact_icon"><FontAwesomeIcon icon={['fab', 'shopify']} /></span></b>


                    </div>
                    <div className="col-sm-2 col-6">
                        <b className="footer-title">SUIVEZ-NOUS</b>
                        <a className="footer_social mb-3" href="#" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>

                        <a className="footer_social" href="#" target="_blank"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>


                    </div>

                </div>


                <div className="row">
                    <div className="col-sm-12 col-xs-12 txt-ctr  mt-5">
                        <div className="copyright_text text-end">
                            <p className=" wow fadeInRight fnt-smlr m-0" data-wow-duration="1s" >© 2023. All Rights Reserved – MEKELTI</p>
                        </div>
                    </div>
                </div>
            </Container>

        </footer>
    );
};

export default FooterMenu;