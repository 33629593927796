import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class CourseService {

    //----------------------------------------------------Ramassage

    listPendingRamassage() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/ramassage/pending`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    createCourseRam(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/course/ramassage/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    viewCourseRam(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/ramassage/view/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    validateCourseRam(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/course/ramassage/validate`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    deleteCourseRam(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/ramassage/cancel/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listCourseRam() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/ramassage/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listCourseRamByLiv() {
        let token = storage.getToken();
        return axios.get(`${api_url}/liv/course/ramassage/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }













    //----------------------------------------------------Livraison


    listPendingLiv() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/liv/pending`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    createCourseLiv(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/course/liv/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    viewCourseLiv(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/liv/view/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    validateCourseLiv(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/course/liv/validate`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    deleteCourseLiv(ref) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/liv/cancel/${ref}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listCourseLiv() {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/course/liv/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    listCourseLivByLiv() {
        let token = storage.getToken();
        return axios.get(`${api_url}/liv/course/liv/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


}

export default new CourseService()