import React, { useEffect } from 'react';
import FooterMenu from '../../../ui/menu/FooterMenu';
import NavBarMenu from '../../../ui/menu/NavBarMenu';
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import Home from './Home';
import FormuleContainer from '../formule/formule.container';
import { clear, loadLoggedUser, logout } from '../../../slices/auth.slice';
import { useDispatch, useSelector } from "react-redux";
import ProfileClient from '../profile/Profile';
import Repas from '../repas/Repas';
import PaymentContainer from '../formule/Payment.container';
import PaymentComplete from '../formule/PaymentComplete';
import Register from '../register/Register';

const MainContainerClient = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);

    useEffect(() => {
        dispatch(loadLoggedUser())
    }, [])

    const dologout = () => {
        dispatch(logout())
    }

    return (
        <>
            <NavBarMenu withShadow={true} loggedUser={loggedUser} onLogout={dologout} />
            <Routes>
                <Route index element={<Home />} />
                <Route path='register' element={<Register />} />
                <Route path='formule' element={<FormuleContainer />} />
                <Route path='espace' element={<ProfileClient />} />
                <Route path='menu' element={<Repas />} />
                <Route path='order-pay/:ref' element={<PaymentContainer />} />
                <Route path='complete-order' element={<PaymentComplete />} />
            </Routes>

            <FooterMenu />
        </>
    );
};

export default MainContainerClient;