import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ClientForm = ({ loading, error, onRegister }) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Ce champ est obligatoire !'),
        password: Yup.string().min(6, 'password not valid !').required('Ce champ est obligatoire !'),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !').required('Ce champ est obligatoire !'),
        nom: Yup.string().required('Ce champ est obligatoire !'),
        prenom: Yup.string().required('Ce champ est obligatoire !'),
        adresse: Yup.string().required('Ce champ est obligatoire !'),
        tel: Yup.string().required('Ce champ est obligatoire !'),
        zip: Yup.string().required('Ce champ est obligatoire !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        onRegister(data)
    }

    return (
        <div className="col-md-12 py-2" >
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }
            <form onSubmit={handleSubmit(onSubmit)} className="row m-0">
                <div className="form-group col-md-12">
                    <label className="fnt-w7 fnt-sm">E-mail</label>
                    <input placeholder="e-mail ..."  {...register("email")} className={`form-control form-control-sm ${errors.email ? 'is-invalid ' : 'form-control'}`} />
                    <p className="text-danger">{errors.email?.message}</p>
                </div>

                <div className="col-md-6 form-group col-6">
                    <label className="fnt-w7 fnt-sm">Nom</label>
                    <input type="text" placeholder="Nom..." {...register("nom")} className={`form-control form-control-sm '}`} />
                    <p className="text-danger">{errors.nom?.message}</p>
                </div>

                <div className="col-md-6 form-group col-6">
                    <label className="fnt-w7 fnt-sm">Prénom</label>
                    <input type="text" placeholder="Prenom ..." {...register("prenom")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.prenom?.message}</p>
                </div>

                <div className="form-group col-md-6">
                    <label className="fnt-w7 fnt-sm">Mot de passe</label>
                    <input type="password" placeholder="password ..."  {...register("password")} className={`form-control form-control-sm ${errors.password ? 'is-invalid' : 'form-control'}`} />
                    <p className="text-danger">{errors.password?.message}</p>
                </div>
                <div className="form-group col-md-6">
                    <label className="fnt-w7 fnt-sm">Confirmation Mot de passe</label>
                    <input type="password" placeholder="password ..."  {...register("confirm_password")} className={`form-control form-control-sm ${errors.password ? 'is-invalid' : 'form-control'}`} />
                    <p className="text-danger">{errors.confirm_password?.message}</p>
                </div>

                <div className="form-group col-md-12 mb-2">
                    <label className="fnt-w7 fnt-sm">Adresse de livraison</label>
                    <textarea rows="2" placeholder="Adresse..." {...register("adresse")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.adresse?.message}</p>
                </div>

                <div className="form-group col-md-4">
                    <label className="fnt-w7 fnt-sm">ZIP *</label>
                    <input placeholder="zip..." {...register("zip")} className={`form-control  form-control-sm'}`} />
                    <p className="text-danger">{errors.zip?.message}</p>
                </div>

                <div className="form-group col-md-8 col-6">
                    <label className="fnt-w7 fnt-sm">Tél</label>
                    <input placeholder="+33 ..."  {...register("tel")} className={`form-control form-control-sm'}`} />
                    <p className="text-danger">{errors.tel?.message}</p>
                </div>





                <div className="col-md-12 text-center" >
                    {loading ?
                        <div className="col-md-12 p-4 text-ctr">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >S'inscrire <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                    }
                </div>

            </form>

        </div>
    );
};

export default ClientForm;