import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import ProductForm from './Product.form';

import ic_plat_1 from '../../../assets/img/plat_1.jpg';
import ic_plat_2 from '../../../assets/img/plat_2.jpeg';
import ic_plat_3 from '../../../assets/img/plat_3.jpg';
import ic_plat_4 from '../../../assets/img/plat_4.jpg';

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listByChef } from '../../../slices/product.slice';
import { api_file_url_product } from '../../../config/Params.constant';

import { getProducts, delProduct } from '../../../services/Fakeapi';


const Product = () => {

    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [show_form, set_show_form] = useState(false)
    const [is_edit, set_is_edit] = useState(false)
    const [sel_item, set_sel_item] = useState()

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listByChef()).unwrap().then(res => {
            if (Array.isArray(res?.rows))
                set_lst_data(res?.rows)
        })
    }

    const onAdd = (item) => {
        set_sel_item(null)
        set_is_edit(false)
        set_show_form(true)
    }

    const onEdit = (item) => {
        set_sel_item(item)
        set_is_edit(true)
        set_show_form(true)
    }

    const onDel = (item) => {
        delProduct(item)
        load_data()
    }

    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row no-marg">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'utensils']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Repas <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>

                            <button className='btn btn-success float-end' onClick={onAdd}><FontAwesomeIcon icon={['fas', 'plus']} />  Nouveau Repas</button>

                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Nom , Réf  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>


            <div className="row m-0 mt-2">

                {
                    Array.isArray(lst_data) && lst_data.map(el =>
                        <div className='col-md-12 p-0 home-banner my-1' style={{ overflow: 'hidden' }}>
                            <div className="row m-0">
                                <div className="col-md-1 p-0">
                                    <img src={`${api_file_url_product}${el?.img}`} alt="" width='100%' />
                                </div>
                                <div className="col-md-9 py-2">
                                    <h5 className='m-0 fnt-w7'>{el?.nom} <span class="ms-3 badge bg-success fnt-w5 fnt-xsm">Publié</span></h5>
                                    <div className="row m-0">
                                        <div className="col-md-6 ps-0">
                                            <b className='txt-dark fnt-w7  fnt-sm'>Description</b>
                                            <p className='text-muted mb-1 fnt-sm'>{el?.description}</p>
                                        </div>
                                        <div className="col-md-4 dvdr-l-gray">
                                            <b className='txt-dark fnt-w7  fnt-sm'>Composition</b>
                                            <p className='text-muted mb-1 fnt-sm'>{el?.composition}</p>
                                        </div>
                                        <div className="col-md-2 dvdr-l-gray">
                                            <b className='txt-dark fnt-w7  fnt-sm'>Disponible</b>
                                            <p className='text-danger fnt-w7 mb-1 fnt-sm'>{el?.max_order}</p>
                                        </div>
                                    </div>




                                    <b className='txt-dark  fnt-w7 fnt-sm'>Cuisine | <span class="badge bg-dark fnt-w5 brd-rad-2">{el?.cuisine}</span> </b>
                                </div>

                                <div className="col-md-2 text-end py-2">
                                    <button className='btn btn-warning btn-sm w-25 me-2' onClick={e => onEdit(el)}><FontAwesomeIcon className='ms-0' icon={['fas', 'pencil']} /></button>
                                    <button className='btn btn-danger btn-sm w-25' onClick={e => onDel(el)}><FontAwesomeIcon className='ms-0' icon={['fas', 'trash']} /></button>


                                </div>
                            </div>
                        </div>
                    )
                }
            </div>



            <ProductForm show={show_form} onClose={e => set_show_form(false)} is_edit={is_edit} item={sel_item} load_data={load_data} />
        </div>
    );
};

export default Product;