import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from "react-redux";
import { admin_routes } from '../../../utils/userRoutes.helper';
import AsideMenu from '../../../ui/menu/AsideMenu';
import { clear, logout, loadLoggedUser } from '../../../slices/auth.slice';
import Home from './Home';
import Users from '../users/Users';
import Orders from '../order/Orders';
import Dettes from '../facture/Dettes';
import FactureCreate from '../facture/FactureCreate';
import Factures from '../facture/Factures';
import OrderDetails from '../order/OrderDetails';
import CoursesRamassage from '../course/CoursesRamassage';
import CourseRamassageCreate from '../course/CourseRamassageCreate';
import CourseRamassageValidate from '../course/CourseRamassageValidate';
import CoursesLivraison from '../course/CoursesLivraison';
import CourseLivraisonCreate from '../course/CourseLivraisonCreate';
import CourseLivraisonValidate from '../course/CourseLivraisonValidate';
import Product from '../product/Product';
import Subscriptions from '../subscription/Subscriptions';


const MainContainerAdmin = ({ match }) => {

    const [isOpen, setIsopen] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);

    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(3);

    const dologout = () => {
        dispatch(logout())
        navigate("/login");
    }

    useEffect(() => {
        dispatch(loadLoggedUser())
    }, [])


    return (
        <>
            <AsideMenu loggedUser={loggedUser} user_routes={admin_routes} onLogOut={dologout} isOpen={isOpen} setIsopen={setIsopen} />

            <div className={isOpen ? "rw-cont bg-gray" : "rw-cont rw-cont-mini bg-gray"}>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path='users' element={<Users />} />
                    <Route path='order' element={<Orders />} />
                    <Route path='order/:ref' element={<OrderDetails />} />
                    <Route path='debts' element={<Dettes />} />
                    <Route path='debts/:ref' element={<FactureCreate />} />
                    <Route path='bills' element={<Factures />} />
                    <Route path='ramassage' element={<CoursesRamassage />} />
                    <Route path='ramassage/create' element={<CourseRamassageCreate />} />
                    <Route path='ramassage/:ref' element={<CourseRamassageValidate />} />
                    <Route path='livraison' element={<CoursesLivraison />} />
                    <Route path='livraison/create' element={<CourseLivraisonCreate />} />
                    <Route path='livraison/:ref' element={<CourseLivraisonValidate />} />
                    <Route path='product' element={<Product />} />
                    <Route path='offre' element={<Subscriptions />} />
                </Routes>
            </div>

        </>
    );
};

export default MainContainerAdmin;