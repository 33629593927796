import React from 'react';
import ClientForm from '../../../ui/form/client.form';
import ChefForm from '../../../ui/form/chef.form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { registerClient, register_chef, uploadImage } from '../../../slices/auth.slice';


import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Register = () => {

    const { loading, error } = useSelector(state => state.auth_store);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onRegisterClient = (data) => {
        dispatch(registerClient(data)).unwrap().then(res => {
            if (res?.done)
                navigate('/login')
        })
    }

    const onRegisterChef = (data, img) => {
        if (img) {
            const formData = new FormData();
            formData.append('file', img);

            dispatch(uploadImage(formData)).unwrap().then(res_upload => {
                if (res_upload?.path)
                    dispatch(register_chef({ ...data, img: res_upload?.path })).unwrap().then(res => {
                        if (res?.done)
                            navigate('/login')
                    })
            })
        } else
            dispatch(register_chef(data)).unwrap().then(res => {
                if (res?.done)
                    navigate('/login')
            })
    }


    return (
        <div className='row m-0'>

            <div className="col-md-6 offset-md-3 my-4">
                <Tabs
                    defaultActiveKey="client"
                    id="tabs-space-client"
                    className="py-0"
                    fill
                >
                    <Tab eventKey="client" title={<><FontAwesomeIcon className='me-3' icon={['fas', 'user-tie']} /> Inscription Client</>}>
                        <ClientForm onRegister={onRegisterClient} loading={loading} error={error} />
                    </Tab>

                    <Tab eventKey="chef" title={<><FontAwesomeIcon className='me-3' icon={['fas', 'user-ninja']} /> Inscription Chef</>}>
                        <ChefForm onRegister={onRegisterChef} loading={loading} error={error} />
                    </Tab>
                </Tabs>
            </div>



        </div>
    );
};

export default Register;