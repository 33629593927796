export const chef_routes = [
    { path: '/app/', label: 'Accueil', icon: 'home', exact: false },
    { path: 'product', label: 'Repas', icon: 'utensils', exact: false },
    { path: 'order', label: 'Commandes', icon: 'truck-loading', exact: false },
    { path: 'bills', label: 'Factures', icon: 'file-invoice-dollar', exact: false },
]

export const admin_routes = [
    { path: '/app/', label: 'Accueil', icon: 'home', exact: false },
    { path: 'users', label: 'Users', icon: 'users', exact: false },
    { path: 'offre', label: 'Offres', icon: 'briefcase', exact: false },
    // { path: 'chef', label: 'Chefs', icon: 'user-ninja', exact: false },
    // { path: 'liv', label: 'Livreur', icon: 'user-astronaut', exact: false },
    // { path: 'client', label: 'Clients', icon: 'user-tie', exact: false },
    { path: 'product', label: 'Repas', icon: 'utensils', exact: false },
    { path: 'order', label: 'Commandes', icon: 'truck-loading', exact: false },
    { path: 'debts', label: 'Dettes', icon: 'hand-holding-usd', exact: false },
    { path: 'bills', label: 'Factures', icon: 'file-invoice-dollar', exact: false },
    { path: 'ramassage', label: 'Ramassage', icon: 'truck', exact: false },
    { path: 'livraison', label: 'Livraison', icon: 'truck', exact: false },
]

export const livreur_routes = [
    { path: '/app/', label: 'Accueil', icon: 'home', exact: false },
    { path: 'ram', label: 'Ramassage', icon: 'truck-loading', exact: false },
    { path: 'liv', label: 'Livraison', icon: 'truck', exact: false },
]